//export const API_HOST = `http://localhost:3000`;
export const API_HOST = `https://medi-pangpang.net`;


export const LAB_ITEMS_COLUMN_1:any = {
    '01': "유럽진드기",
    '02': "북미진드기",
    '03': "개털",
    '04': "고양이털",
    '05': "곰팡이 (Alternaria)",
    '06': "곰팡이 (Aspergillus)",
    '07': "곰팡이 (Malassesia)",
    '08': "참나무",
    '09': "자작나무",
    '10': "오리나무",
    '11': "개암나무",
    '12': "너도밤나무",
    '13': "일본삼나무",
    '14': "환삼덩굴",
    '15': "돼지풀",
    '16': "호밀",
    '17': "쑥",
    '18': "잔디 (Bermuda)",
    '19': "잔디 (Timothy)"
}

export const LAB_ITEMS_COLUMN_2:any = {
    '20': "계란",
    '21': "우유",
    '22': "콩",
    '23': "땅콩",
    '24': "호두",
    '25': "아몬드",
    '26': "잣",
    '27': "밀가루",
    '28': "메밀",
    '29': "새우",
    '30': "게",
    '31': "대구"
}

export const LAB_ITEMS_COLUMNS = {...LAB_ITEMS_COLUMN_1, ...LAB_ITEMS_COLUMN_2}