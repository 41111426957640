import { useEffect, useState } from 'react'
import cn from 'classnames'
import './App.css'

import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import axios from 'axios'
import { CircularProgress } from '@mui/joy'
import { API_HOST, LAB_ITEMS_COLUMN_1, LAB_ITEMS_COLUMN_2, LAB_ITEMS_COLUMNS} from './Constants'
import { customYMD, ANSWER_DICT, getValueByKey, markName } from './util'
import moment from 'moment'
import { renderToString } from 'react-dom/server'
import { Chart, registerables, Tooltip } from 'chart.js'
import { Line } from 'react-chartjs-2'
import type { ChartData, ChartOptions } from 'chart.js';
import * as _ from 'lodash';

Chart.register(...registerables);
Chart.register([Tooltip])

interface PushStatus {
  pushEnabled: boolean;
  pushDayBefore: number;
  pushHour: number;
  pushMinute: number;
  pushTitle: string;
  pushDesc: string;
  repeatEnabled: boolean;
}

interface LineProps {
  options: ChartOptions<'line'>;
  data: ChartData<'line'>;
}

export default function MainPage() {

  const navigate = useNavigate();

  let isTokenRefreshing = false;
  let refreshSubscribers:Array<any> = [];

  const onTokenRefreshed = (accessToken:string) => {
    refreshSubscribers.map((callback) => callback(accessToken))
  }

  const asyncLocalStorage = {
    setItem: function (key:string, value:string) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key:string) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };

  const refreshAuth = () => {
    return axios.post(`${API_HOST}/api/ecrf/refreshToken`, {
      access_token: localStorage.getItem('accessToken'),
      refresh_token: localStorage.getItem('refreshToken')
    })
        .then(async function(res) {
          if (res.status === 202) return Promise.resolve(res);
          axios.defaults.headers.common['token'] = `${res.data.data.access_token}`;
          await asyncLocalStorage.setItem('accessToken', res.data.data.access_token);
          await asyncLocalStorage.setItem('refreshToken', res.data.data.refresh_token);
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
  }

  axios.interceptors.request.use(
      function (config) {
        config.headers!.token = localStorage.accessToken;
        return config;
      });

  axios.interceptors.response.use(
      function (res) {
        return res;
      },
      async function (err) {
        const deniedRequest = err.config;
        console.log(err.response);
        console.log("check 0");

        if (err.response.status === 403) { // 토큰 만료

          await refreshAuth()
              .then((res) => {
                if (res.status === 202) return;
                deniedRequest.headers.token = res.data.data.access_token;
              })
              .catch((err) => {
                console.log("check 4")
                console.log(err)
                isTokenRefreshing = false;
                return Promise.reject(err);
              })

          const originRes = await axios.request(deniedRequest);
          return originRes;

        }
        else {
          console.log(err);
          Logout();
        }
      }
  )


  const [modalState, setModalState] = useState<any>({
    type: "",
    mode: "",
    title: "",
    desc: ""
  });
  const [popupState, setPopupState] = useState<any>({
    type: "",
    mode: "",
    title: "",
    desc: ""
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<any>({
    isOpen: false,
    title: "",
    desc: "",
    accept_label: "",
    deny_label: "",
    accept_callback: () => {}
  });

  const [pageState, setPageState] = useState({
    tab: "participant",
    participant_id: "",
    hospital: "순천향대학교병원",
    total: 0,
    total_page: 1,
    max_per_page: 10,
    page: 1
  });

  const [sortState, setSortState] = useState({
    participant_name: 'asc',
    participant_id: 'asc',
    created_at: 'asc',
    last_surveyed_at: 'asc',
    updated_at: 'asc',
    sort_by: 'participant_id'
  })

  useEffect(() => {
    setListData((prevState) => getValueByKey(sortState, sortState.sort_by) === 'asc' ? _.sortBy(prevState, sortState.sort_by) : _.sortBy(prevState, sortState.sort_by).reverse())
  }, [sortState])

  const [listData, setListData] = useState<Array<any>>([]);
  const [recentViewed, setRecentViewed] = useState<Array<any>>([]);

  const pushToRecentViewed = (id: string) => {
    const idx = recentViewed.indexOf(id);
    if (idx > -1) recentViewed.splice(idx, 1);

    setRecentViewed([id].concat(recentViewed));
  }

  const findParcitipantNameById = (id: string): string => {

    let name:string = ""
    participantList.forEach((item, idx) => {
      if (item.participant_id === id) {
        name = item.participant_name;
      }
    })

    return name
  }

  const [participantInfo, setParticipantInfo] = useState<any>({});
  const [participantList, setParticipantList] = useState<Array<any>>([]);

  const [savedRawItemData, setSavedRawItemData] = useState<any>({});


  const Logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    navigate("/login");
  }

  const LoadParticipantInfo = async (id:string) => {
    setIsLoading(true);
    await axios.post(`${API_HOST}/api/ecrf/getParticipantInfo`, {
      participant_id: id
    })
        .then((res) => {
          setParticipantInfo(res.data.data)
        })
        .catch((err) => {

        })

    setIsLoading(false);
  }

  const LoadParticipantList = async () => {
    setIsLoading(true);
    setListData([]);
    axios.get(`${API_HOST}/api/ecrf/getParticipantList`)
        .then((res) => {
          const listArray = Object.values(res.data.data);
          setListData(_.sortBy(listArray, sortState.sort_by));
          setParticipantList(listArray);
          setPageState(prevState => ({
            ...prevState,
            total: listArray.length,
            total_page: Math.ceil(listArray.length/10),
            page: 1
          }))
        });
    setIsLoading(false);
  }


  //#region TAB FUNCIONS

  //#region LAB funcions

  const [labDate, setLabDate] = useState<string>("");
  const [labType, setLabType] = useState<string>("");
  const [labNumData, setLabNumData] = useState({});
  const [labClassData, setLabClassData] = useState({});

  const LoadLabData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getLabInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);
          setListData(listArray)
          setDashboardLabData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));

          setPageState(prevState => ({
            ...prevState,
            total: listArray.length,
            total_page: Math.ceil(listArray.length/5),
            page: 1
          }))

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }

  const countEnteredLabValues = (data:any): number => {
    const set = new Set();
    Object.keys(data).forEach((key, idx) => {
      if ((key.endsWith('class') || key.endsWith('value')) && data[key] !== null) {
        set.add(key.split('_')[1])
      }
    })

    return set.size;
  }

  const LoadDetailLabData = (data:any) => {
    setSavedRawItemData(data);
    setLabNumData({});
    setLabClassData({});
    setLabDate(data['entered_date']);
    setLabType(data['lab_type']);
    Object.keys(data).forEach((key, idx) => {
      if (key.endsWith('class')) {
        setLabClassData(prevState => ({
          ...prevState,
          [key.split('_')[1]]: data[key]
        }))
      }
      else if (key.endsWith('value')) {
        setLabNumData(prevState => ({
          ...prevState,
          [key.split('_')[1]]: data[key]
        }))
      }
    })
  }

  const ReloadDetailLabData = () => {
    const data = savedRawItemData;
    setLabNumData({});
    setLabClassData({});
    setLabDate(data['entered_date']);
    setLabType(data['lab_type']);
    Object.keys(data).forEach((key, idx) => {
      if (key.endsWith('class')) {
        setLabClassData(prevState => ({
          ...prevState,
          [key.split('_')[1]]: data[key]
        }))
      }
      else if (key.endsWith('value')) {
        setLabNumData(prevState => ({
          ...prevState,
          [key.split('_')[1]]: data[key]
        }))
      }
    })
  }


  const getLabValue = (key:string):any => {
    const idx = Object.keys(labNumData).findIndex((v) => v === key);

    return Object.values(labNumData)[idx];
  }

  const getLabClass = (key:string):any => {
    const idx = Object.keys(labClassData).findIndex((v) => v === key);

    return Object.values(labClassData)[idx];
  }

  const InsertLabData = async (id:number) => {
    setIsLoading(true);
    let hasValueError = false;

    const valueData:any = {};
    Object.keys(labNumData).forEach((key, idx) => {
      const value = getLabValue(key);
      if (value === null || value === "") return;
      if (value < 0 || value > 1000) {
        hasValueError = true;
        return;
      }
      else valueData[`response_${key}_value`] = value;
    })

    if (labType === null || labType === "") {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "검사 방법을 선택해주세요.",
        desc: ""
      })
      setIsLoading(false);
      return;
    }

    if (!moment(labDate, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }

    if (hasValueError) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "입력 값의 범위를 확인해주세요.",
        desc: "입력 값 범위 : 0.05~1000"
      })
      setIsLoading(false);
      return;
    }

    const classData:any = {};
    Object.keys(labClassData).forEach((key, idx) => {
      const value = getLabClass(key);
      if (value === null || value === "") return;
      else classData[`response_${key}_class`] = value;
    })

    const uploadData = {
      participant_id: pageState.participant_id,
      entered_date: labDate,
      lab_type: labType,
      ...valueData,
      ...classData
    }


    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertLabInfo`, {
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 등록되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            LoadLabData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateLabInfo`, {
        id: id,
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 등록되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            LoadLabData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    setModalState({
      type: "",
      mode: ""
    })


    setIsLoading(false);
  }

  const changeLabPosted = async (id: number, status: boolean) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/postLabInfo`, {
      id: id,
      status: status
    })
        .then((res) => {
          setDialogState({
            isOpen: false
          })
          setModalState({
            type: "",
            mode: ""
          })
          LoadLabData(pageState.participant_id);
        })
        .catch((err) => {

        })

    setIsLoading(false);
  }

  const deleteLabData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteLabInfo`, {
      id: id
    })
        .then((res) => {
          setDialogState({
            isOpen: false
          })
          setModalState({
            type: "",
            mode: ""
          })
          LoadLabData(pageState.participant_id);
        })
        .catch((err) => {

        })

    setIsLoading(false);
  }


  //#endregion

  //#region SelfScore functions
  const LoadSelfScoreData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getSelfScoreInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);
          setListData(listArray)
          setSurveyData(listArray.sort((a,b) => b.survey_id - a.survey_id))

          setPageState(prevState => ({
            ...prevState,
            total: listArray.length,
            total_page: Math.ceil(listArray.length/5),
            page: 1
          }))

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }

  const LoadSelfScoreChartData = async (id: string) => {
    setIsLoading(true);
    await axios.post(`${API_HOST}/api/ecrf/getSelfScoreInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);

          let chartLabels: Array<string> = [];
          let chartNumbers: Array<Number> = [];

          listArray
              .sort((a, b) => ( b.submitted_at > a.submitted_at ) ? -1 : ( b.submitted_at == a.entered_date ) ? 0 : 1)
              .forEach((item, idx) => {
                chartLabels.push(item.submitted_at.substring(0, 16).replace("T", " "));
                chartNumbers.push(item.result_value)
              });

          setChartData({
            labels: chartLabels,
            datasets: [
              {
                label: 'Total Score',
                data: chartNumbers
              }
            ]
          });

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }

  //#region SCORAD functions

  const [scoradData, setScoradData] = useState<any>({});

  const LoadScoradData = async (id: string) => {
    setIsLoading(false);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getScoradInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);
          setListData(listArray)
          setDashboardScoradData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));

          setPageState(prevState => ({
            ...prevState,
            total: listArray.length,
            total_page: Math.ceil(listArray.length/5),
            page: 1
          }))

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }

  const LoadScoradChartData = async (id: string) => {
    setIsLoading(true);
    await axios.post(`${API_HOST}/api/ecrf/getScoradInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);

          let chartLabels: Array<string> = [];
          let chartNumbers: Array<Number> = [];

          listArray
              .sort((a, b) => ( b.entered_date > a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1)
              .forEach((item, idx) => {
                chartLabels.push(item.entered_date);
                chartNumbers.push(item.total_score)
              });

          setChartData({
            labels: chartLabels,
            datasets: [
              {
                label: 'Total Score',
                data: chartNumbers
              }
            ]
          });

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }

  const LoadDetailScoradData = (data:any) => {
    setSavedRawItemData(data);
    setScoradData(data);
  }

  const ReloadDetailScoradData = () => {
    const data = savedRawItemData;
    setScoradData(data);
  }


  const InsertScoradData = async (id:number) => {
    setIsLoading(true);

    const uploadData = {
      ...scoradData,
      participant_id: pageState.participant_id
    }

    if (!moment(scoradData.entered_date, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }

    if (scoradData.response_extent < 0 || scoradData.response_extent > 103
        || scoradData.response_intensity < 0 || scoradData.response_intensity > 103
        || scoradData.response_subj_symptoms < 0 || scoradData.response_subj_symptoms > 103) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "입력 값의 범위를 확인해주세요.",
        desc: "입력 값 범위 : 0~103"
      })
      setIsLoading(false);
      return;
    }

    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertScoradInfo`, {
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 등록되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            //LoadScoradData(pageState.participant_id);
            LoadSelfScoreData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateScoradInfo`, {
        id: id,
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 수정되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            //LoadScoradData(pageState.participant_id);
            LoadSelfScoreData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    setModalState({
      type: "",
      mode: ""
    })



    setIsLoading(false);
  }

  const deleteScoradData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteScoradInfo`, {
      id: id
    })
        .then((res) => {
          setDialogState({
            isOpen: false
          })
          setModalState({
            type: "",
            mode: ""
          })
          //LoadScoradData(pageState.participant_id);
          LoadSelfScoreData(pageState.participant_id);
        })
        .catch((err) => {

        })

    setIsLoading(false);
  }

  //#endregion


  //#region EASI functions

  const [easiData, setEasiData] = useState<any>({});

  const LoadEasiData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getEasiInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);
          setListData(listArray)
          setDashboardEasiData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));

          setPageState(prevState => ({
            ...prevState,
            total: listArray.length,
            total_page: Math.ceil(listArray.length/5),
            page: 1
          }))

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }

  const LoadEasiChartData = async (id: string) => {
    setIsLoading(true);
    await axios.post(`${API_HOST}/api/ecrf/getEasiInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);

          let chartLabels: Array<string> = [];
          let chartNumbers: Array<Number> = [];

          listArray
              .sort((a, b) => ( b.entered_date > a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1)
              .forEach((item, idx) => {
                chartLabels.push(item.entered_date);
                chartNumbers.push(item.response_easi)
              });

          setChartData({
            labels: chartLabels,
            datasets: [
              {
                label: 'EASI Score',
                data: chartNumbers
              }
            ]
          });

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }

  const LoadDetailEasiData = (data:any) => {
    setSavedRawItemData(data);
    setEasiData(data);
  }

  const ReloadDetailEasiData = () => {
    const data = savedRawItemData;
    setEasiData(data);
  }

  const InsertEasiData = async (id:number) => {
    setIsLoading(true);

    const uploadData = {
      ...easiData,
      participant_id: pageState.participant_id
    }

    if (!moment(easiData.entered_date, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }

    if (easiData.response_easi < 0 || easiData.response_easi > 72) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "입력 값의 범위를 확인해주세요.",
        desc: "입력 값 범위 : 0~72"
      })
      setIsLoading(false);
      return;
    }

    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertEasiInfo`, {
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 등록되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            LoadEasiData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateEasiInfo`, {
        id: id,
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 수정되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            LoadEasiData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    setModalState({
      type: "",
      mode: ""
    })



    setIsLoading(false);
  }

  const deleteEasiData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteEasiInfo`, {
      id: id
    })
        .then((res) => {
          setDialogState({
            isOpen: false
          })
          setModalState({
            type: "",
            mode: ""
          })
          LoadEasiData(pageState.participant_id);
        })
        .catch((err) => {

        })

    setIsLoading(false);
  }

  //#endregion


  //#region ADR functions

  const [adrData, setAdrData] = useState<any>({});

  const LoadAdrData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getAdrInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);
          setListData(listArray)
          setDashboardAdrData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));


          setPageState(prevState => ({
            ...prevState,
            total: listArray.length,
            total_page: Math.ceil(listArray.length/5),
            page: 1
          }))

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }

  const LoadDetailAdrData = (data:any) => {
    setSavedRawItemData(data);
    setAdrData(data);
  }

  const ReloadDetailAdrData = () => {
    const data = savedRawItemData;
    setAdrData(data);
  }

  const InsertAdrData = async (id:number) => {
    setIsLoading(true);

    const uploadData = {
      ...adrData,
      participant_id: pageState.participant_id
    }

    if (!moment(adrData.entered_date, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }


    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertAdrInfo`, {
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 등록되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            LoadAdrData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateAdrInfo`, {
        id: id,
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 수정되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            LoadAdrData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    setModalState({
      type: "",
      mode: ""
    })



    setIsLoading(false);
  }

  const deleteAdrData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteAdrInfo`, {
      id: id
    })
        .then((res) => {
          setDialogState({
            isOpen: false
          })
          setModalState({
            type: "",
            mode: ""
          })
          LoadAdrData(pageState.participant_id);
        })
        .catch((err) => {

        })

    setIsLoading(false);
  }

  //#endregion


  //#region OAS functions

  const [oasData, setOasData] = useState<any>({});

  const LoadOasData = async (id:string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getOasInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);
          setListData(listArray)
          setDashboardOasData(listArray.sort((a, b) => b.metadata_id - a.metadata_id));

          setPageState(prevState => ({
            ...prevState,
            total: listArray.length,
            total_page: Math.ceil(listArray.length/5),
            page: 1
          }))

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }

  const LoadDetailOasData = (data:any) => {
    setSavedRawItemData(data);
    setOasData(data);
  }

  const ReloadDetailOasData = () => {
    const data = savedRawItemData;
    setOasData(data);
  }

  const InsertOasData = async (id:number) => {
    setIsLoading(true);

    const filteredData:any = {};

    Object.keys(oasData).forEach((key) => {
      if (oasData[key] !== null && oasData[key] !== "") filteredData[key] = oasData[key]
    })

    const uploadData = {
      ...filteredData,
      participant_id: pageState.participant_id
    }


    if (!moment(oasData.entered_date, 'YYYY-MM-DD', true).isValid()) {
      setPopupState({
        type: "message",
        mode: "fail",
        title: "날짜를 올바르게 입력해주세요.",
        desc: "예시: 2023-02-08"
      })
      setIsLoading(false);
      return;
    }


    if (id === 0) { // 새 데이터 추가
      await axios.post(`${API_HOST}/api/ecrf/insertOasInfo`, {
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 등록되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            LoadOasData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    else {
      await axios.post(`${API_HOST}/api/ecrf/updateOasInfo`, {
        id: id,
        data: uploadData
      })
          .then((res) => {
            setPopupState({
              type: "message",
              mode: "success",
              title: "성공적으로 수정되었습니다.",
              desc: "데이터가 성공적으로 입력되었습니다."
            })
            LoadOasData(pageState.participant_id);
          })
          .catch((err) => {
            setPopupState({
              type: "message",
              mode: "fail",
              title: "등록 실패하였습니다.",
              desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
            })
          })
    }
    setModalState({
      type: "",
      mode: ""
    })



    setIsLoading(false);
  }


  const getOasString = (item:any) => {
    const filteredValue: Array<string> = [];
    Object.keys(item).forEach((key:string) => {
      if (key.includes("response_allergen") && (item[key] !== null && item[key] !== "")) filteredValue.push(item[key])
    })

    return filteredValue.join(", ")
  }

  const deleteOasData = async (id: number) => {
    setIsLoading(true);

    await axios.post(`${API_HOST}/api/ecrf/deleteOasInfo`, {
      id: id
    })
        .then((res) => {
          setDialogState({
            isOpen: false
          })
          setModalState({
            type: "",
            mode: ""
          })
          LoadOasData(pageState.participant_id);
        })
        .catch((err) => {

        })

    setIsLoading(false);
  }

  //#endregion


  //#region Survey functions

  const [surveyData, setSurveyData] = useState<any>({});

  const LoadSurveyData = async (id: string) => {
    setIsLoading(true);
    setListData([]);
    await axios.post(`${API_HOST}/api/ecrf/getSurveyInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);
          setListData(listArray)
          setDashboardSurveyData(listArray.sort((a, b) => a.submitted_at - b.submitted_at));

          setPageState(prevState => ({
            ...prevState,
            total: listArray.length,
            total_page: Math.ceil(listArray.length/6),
            page: 1
          }));


        })
        .catch((err) => {
        })

    setIsLoading(false);
  };

  const LoadSurveyChartData = async (id: string, tab: string) => {
    setIsLoading(true);
    await axios.post(`${API_HOST}/api/ecrf/getSurveyInfo`, {
      participant_id: id
    })
        .then((res) => {
          const listArray:Array<any> = Object.values(res.data.data);

          let chartLabels: Array<string> = [];
          let chartNumbers: Array<Number> = [];

          listArray
              .sort((a, b) => ( b.entered_date > a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1)
              .forEach((item, idx) => {
                chartLabels.push(item.surveyed_at.slice(0,10));

                switch (tab) {
                  case 'adct':
                    chartNumbers.push(item.adct_score);
                    break;
                  case 'dlqi':
                    chartNumbers.push(item.qol_score)
                    break;
                  case 'dfi':
                    chartNumbers.push(item.dfi_score)
                    break;
                  case 'poem':
                    chartNumbers.push(item.poem_score)
                    break;
                }

              });

          setChartData({
            labels: chartLabels,
            datasets: [
              {
                label: `${tab.toUpperCase()} Score`,
                data: chartNumbers
              }
            ]
          });

        })
        .catch((err) => {
        })

    setIsLoading(false);
  }


  const LoadDetailSurveyData = (data:any) => {
    setSavedRawItemData(data);
    setSurveyData(data);
  }


  const getAdrString = (data:any) => {
    const adrKeys = ['response_5b', 'response_5d', 'response_5f', 'response_5h', 'response_5j', 'response_5l', 'response_5n']
    let adrValue:Array<string> = [];
    adrKeys.forEach((key:string, idx) => {
      if (data[key] !== null && data[key] !== undefined && Array.isArray(data[key])) {
        data[key].forEach((answer:number) => {
          adrValue.push(getValueByKey(ANSWER_DICT, key)[answer]);
        })
      }
    })

    if (adrValue.length === 0) return null
    else return adrValue.join(', ')
  }

  //#endregion


  //#region Dashboard functions

  const [dashboardSurveyData, setDashboardSurveyData] = useState<Array<any>>([]);
  const [dashboardScoradData, setDashboardScoradData] = useState<Array<any>>([]);
  const [dashboardLabData, setDashboardLabData] = useState<Array<any>>([]);
  const [dashboardEasiData, setDashboardEasiData] = useState<Array<any>>([]);
  const [dashboardAdrData, setDashboardAdrData] = useState<Array<any>>([]);
  const [dashboardOasData, setDashboardOasData] = useState<Array<any>>([]);

  const LoadDashboardData = async (id: string) => {
    setIsLoading(true);
    await LoadSurveyData(id);
    await LoadScoradData(id);
    await LoadLabData(id);
    await LoadEasiData(id);
    await LoadAdrData(id);
    await LoadOasData(id);
    setIsLoading(false);
  }


  //#endregion


  //#endregion


  //#region GRAPH functions

  const [chartState, setChartState] = useState<any>({
    tab: ''
  });

  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [
      {
        label: 'Total Score',
        data: []
      }
    ]
  });



  //#endregion

  const LoadPushData = async () => {
    setIsLoading(true);
    await axios.get(`${API_HOST}/api/ecrf/getPushInfo`)
        .then((res) => {

          if (res.data.data.length !== 0)  {
            setAdminState({
              pushEnabled: res.data.data[0].is_enabled,
              pushDayBefore: res.data.data[0].push_day_before,
              pushHour: res.data.data[0].push_hour,
              pushMinute: res.data.data[0].push_minute,
              pushTitle: res.data.data[0].content_title,
              pushDesc: res.data.data[0].content_desc,
              repeatEnabled: res.data.data[0].is_repeat_enabled
            });
          }

        })
        .catch((err) => {
        })

    setIsLoading(false);
  };

  const UpdatePushData = async () => {

    //schedule_cron: `0 ${adminState.pushHour-9 < 0 ? adminState.pushHour-9+24 : adminState.pushHour-9} ${adminState.pushHour-9 < 0 ? adminState.pushDay-1 : adminState.pushDay} * *`,

    setIsLoading(true);
    console.log(adminState)
    await axios.post(`${API_HOST}/api/ecrf/updatePushInfo`,
        {
          is_enabled: adminState.pushEnabled,
          push_day_before: adminState.pushDayBefore,
          push_hour: adminState.pushHour,
          push_minute: adminState.pushMinute,
          content_title: adminState.pushTitle,
          content_desc: adminState.pushDesc,
          is_repeat_enabled: adminState.repeatEnabled
        })
        .then((res) => {

          setPopupState({
            type: "message",
            mode: "success",
            title: "성공적으로 수정되었습니다.",
            desc: "데이터가 성공적으로 입력되었습니다."
          })

        })
        .catch((err) => {

          setPopupState({
            type: "message",
            mode: "fail",
            title: "등록 실패하였습니다.",
            desc: "데이터 등록에 실패하였습니다. 다시 시도해주세요."
          })
        })

    setIsLoading(false);
  };

  const [adminState, setAdminState] = useState<PushStatus>({
    pushEnabled: false,
    pushDayBefore: 5,
    pushHour: 10,
    pushMinute: 0,
    pushTitle: '',
    pushDesc: '',
    repeatEnabled: false
  })

  useEffect(() => {

    if (localStorage.getItem('accessToken') === null || localStorage.getItem('refreshToken') === null) {
      Logout();
      return;
    }
    else {
      refreshAuth()
          .then(() => {
            axios.get(`${API_HOST}/api/ecrf/userInfo`)
                .then((res) => {
                  setPageState(prevState => ({
                    ...prevState,
                    hospital: res.data.data.hospital.hospital_name
                  }))
                });

            if (pageState.tab === "participant") {
              LoadParticipantList();
            }
          })
          .catch((err) => {
            Logout();
          })
          .finally(() => {
          })
    }

  }, [])


  return (
      <div style={{width: '100%', minWidth: 1280, height: '100%', minHeight: 720, overflow: 'hidden'}}>

        <div style={{height: 50, display: 'flex', alignItems: 'center', justifyContent: 'space-between',borderBottom: '1px solid #E9ECEF'}}>
          <img style={{width: 250, marginLeft: 20}} src={`./img/login_logo.png`} alt="login page logo"/>

          <img style={{width: 24, marginLeft: 'auto'}} src={`./img/ic_hospital.png`} alt="hospital icon"/>
          <div style={{marginLeft: 6, fontSize: 14}}>{pageState.hospital} 로그인됨  |  </div>
          <img style={{width: 20, marginLeft: 6}} src={`./img/info.png`} alt="info icon"/>
          <a href="https://ecrf-guide-01.notion.site/eCRF-39b54c5eba914113bc9b70f3bd7f048b" target="_blank" rel="noopener noreferrer"
             style={{marginLeft: 4, fontSize: 14, cursor: 'pointer', textDecoration: 'underline'}}>
            가이드 링크
          </a>
          <span style={{marginLeft: 4,fontSize: 14}}>|</span>

          {pageState.hospital === '관리자' ?
              <div style={{marginLeft: 6, fontSize: 14, cursor: 'pointer'}}
                   onClick={async() => {
                     await LoadPushData()
                     setModalState({
                       type: 'admin',
                       mode: "push"
                     })
                   }}><span style={{textDecoration: 'underline', marginRight: 4}}>Admin</span> | </div>
              : null}
          <div style={{marginLeft: 6, marginRight: 20, fontSize: 14, cursor: 'pointer', textDecoration: 'underline'}}
               onClick={() => {
                 Logout();
               }}>로그아웃</div>
        </div>

        {pageState.hospital === '관리자' && modalState.type === "admin" ?
            <div className={cn('modalBase')} style={{minHeight: 740}}>
              <div className={cn('modalPaper')} style={{width: 800, height: 650, padding: 20}}>
                <div style={{display: 'flex', height: 40, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>

                  <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                    <div>관리자 설정</div>
                  </div>

                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         setModalState({
                           type: "",
                           mode: ""
                         })
                       }}/>
                </div>

                <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
                  <div style={{fontSize: '20px', fontWeight: 700}}>설문 미참여자 대상 월별 푸시 설정</div>
                  <div style={{display: 'grid', gap: 10, padding: '8px 12px', marginLeft: 'auto', gridTemplateColumns: '70px 60px 60px 60px 60px 60px ', alignItems: 'center', fontSize: '14px',
                    background: '#ebebeb', borderRadius: '22px', boxShadow: 'inset 0 0 2px gray'}}>
                    <div className={cn({'graphButton': true, 'graphButtonSelected': modalState.mode === 'push'})}
                         onClick={() => {
                           setModalState({
                             ...modalState,
                             mode: "push"
                           })
                         }}>PUSH</div>

                  </div>
                </div>

                <div style={{width: '100%', height: 700, marginTop: 20, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>
                  <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 20, marginLeft: 20, gap: 30, height: 40, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
                    <div style={{borderRadius: 8, border: '1.5px solid gray'}}>푸시 전송 여부</div>

                    <form style={{border: 'none', display: 'flex', alignItems: 'center', gap: 3}}>
                      <input
                          type='radio'
                          id="pushTrue"
                          name="pushRadio"
                          defaultChecked={adminState.pushEnabled}
                          onChange={(e) => {
                            console.log(e.target.checked)
                            if (e.target.checked) {
                              setAdminState({
                                ...adminState,
                                pushEnabled: true
                              });
                            }

                          }} />
                      <label style={{marginTop: 3}}>ON</label>
                      <input
                          type='radio'
                          id="pushFalse"
                          name="pushRadio"
                          defaultChecked={!adminState.pushEnabled}
                          style={{marginLeft: 15}}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setAdminState({
                                ...adminState,
                                pushEnabled: false
                              });
                            }
                          }} />
                      <label style={{marginTop: 3}}>OFF</label>
                    </form>
                  </div>


                  <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 20, marginLeft: 20, gap: 30, height: 40, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
                    <div style={{borderRadius: 8, border: '1.5px solid gray'}}>푸시 전송 시점</div>
                    <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                      <div>매월</div>
                      <input
                          type='number'
                          className={cn('cellTextInput')}
                          style={{width: 60, textAlign: 'center', height: '100%', background: '#F3F3F3', borderRadius: 8, fontSize: 20, marginLeft: 10}}
                          defaultValue={adminState.pushDayBefore}
                          onChange={(e) => {
                            setAdminState({
                              ...adminState,
                              pushDayBefore: Number(e.target.value)
                            })
                          }}>
                      </input>
                      <div>일전</div>
                      <input
                          type='number'
                          className={cn('cellTextInput')}
                          style={{width: 60, textAlign: 'center', height: '100%', background: '#F3F3F3', borderRadius: 8, fontSize: 20, marginLeft: 10}}
                          defaultValue={adminState.pushHour}
                          onChange={(e) => {
                            setAdminState({
                              ...adminState,
                              pushHour: Number(e.target.value)
                            })
                          }}>
                      </input>
                      <div>시</div>
                      <input
                          type='number'
                          className={cn('cellTextInput')}
                          style={{width: 60, textAlign: 'center', height: '100%', background: '#F3F3F3', borderRadius: 8, fontSize: 20, marginLeft: 10}}
                          defaultValue={adminState.pushMinute}
                          onChange={(e) => {
                            setAdminState({
                              ...adminState,
                              pushMinute: Number(e.target.value)
                            })
                          }}>
                      </input>
                      <div>분</div>
                      <div>(KST 0시 ~ 23시 59분)</div>

                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 20, marginLeft: 20, gap: 30, height: 160, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
                    <div style={{borderRadius: 8, border: '1.5px solid gray', height: 40}}>푸시 전송 내용</div>
                    <div>
                      <div style={{display: 'flex', gap: 20, alignItems: 'center', height: 40}}>
                        <div>제목</div>
                        <input
                            type='text'
                            className={cn('cellTextInput')}
                            style={{width: 400, height: '100%', background: '#F3F3F3', borderRadius: 8, fontSize: 18, paddingLeft: 15}}
                            defaultValue={adminState.pushTitle}
                            onChange={(e) => {
                              setAdminState({
                                ...adminState,
                                pushTitle: e.target.value
                              })
                            }}>
                        </input>
                      </div>
                      <div style={{display: 'flex', gap: 20, alignItems: 'center', height: 100, marginTop: 10}}>
                        <div style={{alignSelf: 'start'}}>내용</div>
                        <textarea
                            className={cn('cellTextInput')}
                            style={{width: 390, height: 100, background: '#F3F3F3', borderRadius: 8, fontSize: 18, marginTop: 40, padding: 15, resize: 'none', lineHeight: '20px'}}
                            defaultValue={adminState.pushDesc}
                            onChange={(e) => {
                              setAdminState({
                                ...adminState,
                                pushDesc: e.target.value
                              })
                            }}>
                  </textarea>

                      </div>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 30, marginLeft: 20, gap: 30, height: 40, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
                    <div style={{borderRadius: 8, border: '1.5px solid gray'}}>푸시 반복 전송 여부</div>
                    <form style={{border: 'none', display: 'flex', alignItems: 'center', gap: 3}}>
                      <input
                          type='radio'
                          id="repeatTrue"
                          name="repeatRadio"
                          defaultChecked={adminState.repeatEnabled}
                          onChange={(e) => {
                            console.log(e.target.checked)
                            if (e.target.checked) {
                              setAdminState({
                                ...adminState,
                                repeatEnabled: true
                              });
                            }

                          }} />
                      <label style={{marginTop: 3}}>ON</label>
                      <input
                          type='radio'
                          id="repeatFalse"
                          name="repeatRadio"
                          defaultChecked={!adminState.repeatEnabled}
                          style={{marginLeft: 15}}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setAdminState({
                                ...adminState,
                                repeatEnabled: false
                              });
                            }
                          }} />
                      <label style={{marginTop: 3}}>OFF</label>
                    </form>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: '0.7fr 3fr', marginTop: 20, marginLeft: 20, gap: 30, height: 40, fontSize: 16, lineHeight: '40px', fontWeight: 700, textAlign: 'center'}}>
                    <div style={{borderRadius: 8, border: '1.5px solid gray'}}>안내</div>
                    <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>

                      <div>해당 월에 설문을 참여하지 않은 사용자에게만 푸시가 전송됩니다.</div>

                    </div>
                  </div>


                  <div style={{display: 'flex', gap: 20, justifyContent: 'center'}}>
                    <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginTop: 20, fontSize: 16, fontWeight: 700}}
                            onClick={async () => {
                              console.log(adminState)

                              if (adminState.pushDayBefore > 20 || adminState.pushDayBefore < 0 || !Number.isInteger(adminState.pushDayBefore)) {
                                setPopupState({
                                  type: "message",
                                  mode: "fail",
                                  title: "날짜를 올바르게 입력해주세요.",
                                  desc: "범위: 0~20 / 정수만 입력 가능"
                                })
                                return;
                              }
                              else if (adminState.pushHour > 23 || adminState.pushHour < 0 || !Number.isInteger(adminState.pushHour)) {
                                setPopupState({
                                  type: "message",
                                  mode: "fail",
                                  title: "시간(시)을 올바르게 입력해주세요.",
                                  desc: "범위: 0~23 / 정수만 입력 가능"
                                })
                                return;
                              }
                              else if (adminState.pushMinute > 59 || adminState.pushMinute < 0 || !Number.isInteger(adminState.pushMinute)) {
                                setPopupState({
                                  type: "message",
                                  mode: "fail",
                                  title: "시간(분)을 올바르게 입력해주세요.",
                                  desc: "범위: 0~59 / 정수만 입력 가능"
                                })
                                return;
                              }

                              await UpdatePushData();
                              setModalState({
                                type: "",
                                mode: ""
                              })

                            }}>
                      저장
                    </button>
                  </div>


                </div>


              </div>
            </div>
            : null}


        <div style={{height: 'calc(100% - 50px)', display: 'flex'}}>
          <div style={{width: 190, padding: '16px 12px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img style={{width: 170, cursor: 'pointer'}} src={`./img/btn_participant_list.png`} alt="participant list button"
                 onClick={() => {
                   setPageState(prevState => ({
                     ...prevState,
                     tab: 'participant',
                     participant_id: ''
                   }))
                   LoadParticipantList();
                 }}/>
            <div style={{width: 170, height: 1, borderBottom: '1px solid #E9ECEF', marginTop: 16}}/>

            <div style={{width: 150}}>
              <div style={{fontSize: '14px', fontWeight: '600', marginTop: 16, color: '#ADB5BD', marginBottom: 20}}>최근 본 참여자</div>

              {recentViewed.map((id, idx) => {
                return(
                    <div>
                      <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, cursor: 'pointer'}}
                           onClick={async () => {
                             setIsLoading(true);
                             setPageState(prevState => ({
                               ...prevState,
                               participant_id: id
                             }))
                             await LoadParticipantInfo(id);
                             await LoadDashboardData(id);
                             setPageState(prevState => ({
                               ...prevState,
                               tab: 'dashboard',
                               participant_id: id
                             }))
                             setIsLoading(false);
                           }}>
                        <img style={{width: 24}} src={`./img/ic_user.png`} alt="user icon"/>
                        <div style={{fontSize: '14px', fontWeight: '400', marginLeft: 10, color: '#8A92A6'}}>{markName(findParcitipantNameById(id))}</div>
                      </div>
                    </div>
                )
              })
              }

            </div>
          </div>

          <div style={{width: 'calc(100% - 190px)', background: '#E9ECEF'}}>
            <img style={{height: 170, width: '100%', objectFit: 'cover', borderRadius: '0 0 16px 16px'}} src={`./img/dashboard_bg.svg`} alt="dashboard background"/>
            <div style={{position: 'relative', fontSize: '28px', fontWeight: '700', marginLeft: 40, top: -140, color: 'white'}}>
              {pageState.hospital}
            </div>

            {/* 메인 리스트 paper */}
            { pageState.tab === "participant" ?
                <div style={{position: 'relative', width: '94%', height: '75%', background: 'white', borderRadius: '8px', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', top: -115, marginLeft: 40, overflow: 'auto'}}>
                  <div style={{display: 'flex', alignItems: 'center', height: 60, padding: '0 20px', borderBottom: '1px solid #E9ECEF', gap: 10}}>
                    <div style={{fontSize: '24px', fontWeight: '700'}}>참여자 리스트</div>
                    <div style={{fontSize: '18px', fontWeight: '700', color: '#54595E', marginTop: 5}}>
                      {(pageState.page-1) * 10 + 1}-{(pageState.page)*10 > pageState.total ? pageState.total : (pageState.page)*10} / total: {pageState.total}
                    </div>
                  </div>

                  <div style={{height: 40, display: 'grid', gridTemplateColumns: '10% 15% 20% 20% 20% 1fr', alignItems: 'center', padding: '0 20px', fontWeight: 600, fontSize: '16px', color: '#54595E', borderBottom: '1px solid #E9ECEF'}}>
                    <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                      <>성명</>
                      <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'participant_name' ? 1 : 0.5}}
                           src={`./img/ic_sort_${sortState.participant_name}.png`} alt="오름차순 정렬"
                           onClick={() => {
                             setSortState((prevState) => ({
                               ...prevState,
                               sort_by: 'participant_name',
                               participant_name: sortState.sort_by !== 'participant_name' ? prevState.participant_name : sortState.participant_name === 'desc' ? 'asc' : 'desc'
                             }))
                           }}/>
                    </div>
                    <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                      <>연구 등록 번호</>
                      <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'participant_id' ? 1 : 0.5}}
                           src={`./img/ic_sort_${sortState.participant_id}.png`} alt="오름차순 정렬"
                           onClick={() => {
                             setSortState((prevState) => ({
                               ...prevState,
                               sort_by: 'participant_id',
                               participant_id: sortState.sort_by !== 'participant_id' ? prevState.participant_id : sortState.participant_id === 'desc' ? 'asc' : 'desc'
                             }))
                           }}/>
                    </div>
                    <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                      <>가입일자</>
                      <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'created_at' ? 1 : 0.5}}
                           src={`./img/ic_sort_${sortState.created_at}.png`} alt="오름차순 정렬"
                           onClick={() => {
                             setSortState((prevState) => ({
                               ...prevState,
                               sort_by: 'created_at',
                               created_at: sortState.sort_by !== 'created_at' ? prevState.created_at : sortState.created_at === 'desc' ? 'asc' : 'desc'
                             }))
                           }}/>
                    </div>
                    <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                      <>최근 설문참여일</>
                      <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'last_surveyed_at' ? 1 : 0.5}}
                           src={`./img/ic_sort_${sortState.last_surveyed_at}.png`} alt="오름차순 정렬"
                           onClick={() => {
                             setSortState((prevState) => ({
                               ...prevState,
                               sort_by: 'last_surveyed_at',
                               last_surveyed_at: sortState.sort_by !== 'last_surveyed_at' ? prevState.last_surveyed_at : sortState.last_surveyed_at === 'desc' ? 'asc' : 'desc'
                             }))
                           }}/>
                    </div>
                    <div style={{display:'flex', gap: 10, alignItems: 'center'}}>
                      <>최근 업데이트일</>
                      <img style={{width: 20, cursor: 'pointer', opacity: sortState.sort_by === 'updated_at' ? 1 : 0.5}}
                           src={`./img/ic_sort_${sortState.updated_at}.png`} alt="오름차순 정렬"
                           onClick={() => {
                             setSortState((prevState) => ({
                               ...prevState,
                               sort_by: 'updated_at',
                               updated_at: sortState.sort_by !== 'updated_at' ? prevState.updated_at : sortState.updated_at === 'desc' ? 'asc' : 'desc'
                             }))
                           }}/>
                    </div>
                    <div>상세 정보 및 관리</div>
                  </div>

                  {listData.slice((pageState.page-1) * 10, (pageState.page)*10).map((item, idx) => {
                    return (
                        <div style={{height: 36, display: 'grid', gridTemplateColumns: '10% 15% 20% 20% 20% 1fr', alignItems: 'center', padding: '0 20px', fontWeight: 400, fontSize: '14px', borderBottom: '1px solid #E9ECEF',
                          background: idx%2 === 1 ? '#F6F6F6' : 'none'}}>
                          <div>{markName(item.participant_name)}</div>
                          <div>{item.participant_id}</div>
                          <div>{item.created_at ? item.created_at.split("T")[0] : ""}</div>
                          <div>{item.last_surveyed_at ? item.last_surveyed_at.split("T")[0] : ""}</div>
                          <div>{item.updated_at ? item.updated_at.split("T")[0] : ""}</div>
                          <div>
                            <button style={{width: 110, height: 24, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={async () => {
                                      setIsLoading(true);
                                      setPageState(prevState => ({
                                        ...prevState,
                                        participant_id: item.participant_id
                                      }))
                                      pushToRecentViewed(item.participant_id);
                                      await LoadParticipantInfo(item.participant_id);
                                      await LoadDashboardData(item.participant_id);
                                      setPageState(prevState => ({
                                        ...prevState,
                                        tab: 'dashboard',
                                        participant_id: item.participant_id
                                      }))
                                      setIsLoading(false);
                                    }}>
                              상세 보기
                            </button>
                          </div>
                        </div>)
                  })}


                  <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 5}}>
                    <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === 1) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page-1
                           }))
                         }}/>

                    {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
                      return (
                          <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                               onClick={() => {
                                 setPageState(prevState => ({
                                   ...prevState,
                                   page: i+1
                                 }))
                               }}>
                            {i+1}
                          </div>
                      )})}

                    <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page+1
                           }))
                         }}/>

                  </div>

                </div> : null}

            {/* 탭 네비게이션 */}
            { pageState.tab !== "participant" ?
                <div style={{position: 'relative', width: '94%', top: -115, marginLeft: 40, overflow: 'auto'}}>
                  <div style={{display: 'flex', alignItems: 'center', height: 60, padding: '0 20px', background: 'white', borderRadius: '8px', gap: 10}}>
                    <div style={{fontSize: '24px', fontWeight: '700'}}>{markName(participantInfo.participant_name)}</div>
                    <div style={{fontSize: '16px', fontWeight: '400', color: '#232D42', marginTop: 5}}>| 연구 등록 번호 {participantInfo.participant_id}</div>
                  </div>

                  <div style={{display: 'grid', gap: 2, marginTop: 30, gridTemplateColumns: '80px 80px 70px 90px 80px 80px 140px 1fr', alignItems: 'center', fontSize: '14px'}}>
                    <div className={cn({'tabButton': true, 'tabButtonSelected': pageState.tab === 'dashboard'})}
                         onClick={() => {
                           LoadDashboardData(participantInfo.participant_id);
                           setPageState(prevState => ({...prevState, tab: 'dashboard'}));
                         }}>대시보드</div>
                    <div className={cn({'tabButton': true, 'tabButtonSelected': pageState.tab === 'survey'})}
                         onClick={() => {
                           setPageState(prevState => ({...prevState, tab: 'survey', max_per_page: 6}));
                           LoadSurveyData(participantInfo.participant_id);
                         }}>설문결과</div>

                  </div>
                </div> : null}


            {/* 환자별 대시보드 paper */}
            { pageState.tab === "dashboard" ?
                <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>
                  <div style={{display: 'flex', gap: 10, marginTop: 15}}>
                    <div style={{width: '49.5%', height: 70, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', padding: 15}}>
                      <div style={{fontSize: 16, fontWeight: 600}}>환자 정보</div>
                      <div style={{width: '100%', height: 20, marginTop: 12, display: 'grid', gridTemplateColumns: '0.7fr 1fr 1fr 1fr', color:'rgba(84, 89, 94, 0.6)', fontSize: 12, fontWeight: 400}}>
                        <div>성명</div>
                        <div>연구 등록 번호</div>
                        <div>가입일자</div>
                        <div>최근 설문참여일</div>
                      </div>
                      <div style={{width: '100%', height: 20, marginTop: 4, display: 'grid', gridTemplateColumns: '0.7fr 1fr 1fr 1fr', color:'#54595E', fontSize: 14, fontWeight: 600}}>
                        <div>{markName(participantInfo.participant_name)}</div>
                        <div>{participantInfo.participant_id}</div>
                        <div>{participantInfo.created_at ? participantInfo.created_at.slice(0,10) : "-"}</div>
                        <div>{participantInfo.last_surveyed_at ? participantInfo.last_surveyed_at.slice(0,10) : "-"}</div>
                      </div>
                    </div>


                    <div style={{width: '49.5%', height: 70, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', padding: 15}}>
                      <div style={{fontSize: 16, fontWeight: 600, display: 'flex'}}>
                        <div>최근 설문 결과 보기</div>
                        <div style={{textDecoration: 'underline', color: '#3A57E8', fontSize: 14, fontWeight: 600, marginLeft: 'auto', cursor: 'pointer'}} onClick={() => {
                          setPageState(prevState => ({...prevState, tab: 'survey', max_per_page: 6}));
                          LoadSurveyData(pageState.participant_id);
                        }}>더보기</div>
                      </div>
                      <div style={{display: 'flex', gap: 10, marginTop: 16}}>
                        {dashboardSurveyData.map((item, idx) => {
                          return (
                              <button style={{width: 100, height: 35, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', fontSize: 12}}
                                      onClick={() => {
                                        LoadDetailSurveyData(item);
                                        setModalState({
                                          type: "survey",
                                          mode: "detail",
                                          created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                                          updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                                          posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-"
                                        });
                                      }}>
                                {item.surveyed_at.slice(0,10)}
                              </button>
                          )
                        })}
                      </div>

                    </div>
                  </div>

                  <div style={{maxHeight: 300, marginTop: 14, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', padding: 15, overflowY: 'auto'}}>
                    <div style={{fontSize: 16, fontWeight: 600}}>최근 증상 평가 결과 및 점수</div>
                    <div className={cn({'tabButton': true, 'tabButtonSelected': true})} style={{marginLeft: 'auto', width: 80, background: '#2875e6', borderRadius: '50px'}}
                         onClick={() => {
                           setModalState({
                             type: "chart"
                           });
                           setChartState({
                             tab: "selfscore"
                           });
                           LoadSelfScoreChartData(participantInfo.participant_id);
                         }}>Chart</div>
                    <div className={cn('dashboardTableTop')} style={{marginTop: 10}}>
                      <div>항목</div>
                      <div>작성 내용</div>
                      <div>작성 일시</div>
                      <div/><div/>
                    </div>



                    <div className={cn('dashboardTableRow1')}>
                      <div>Lab</div>
                      <div>
                        {dashboardLabData.length > 0
                            ? Object.entries(dashboardLabData[0]).map((element: any, idx: number, arr) => {
                              let [key, value] = element;
                              if (value === null || key.substring(9, 11).length !== 2) {
                                if (idx-1 >= 0 && arr[idx-1][1] !== null && arr[idx-1][0].charAt(12) === 'v') return " | "
                              }
                              else if (key.charAt(12) === 'v') return LAB_ITEMS_COLUMNS[key.substring(9, 11)] + ": " + value;
                              else if (key.charAt(12) === 'c') {
                                if (idx-1 >= 0 && arr[idx-1][1] !== null) return ", " + value + " | "
                                else return LAB_ITEMS_COLUMNS[key.substring(9, 11)] + ": " + value + " | ";
                              }
                            })
                            : "없음"}
                      </div>
                      <div>
                        {dashboardLabData.length > 0
                            ? dashboardLabData[0].created_at.slice(0,10)
                            : ""}
                      </div>

                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             LoadDetailLabData(dashboardLabData[0])
                             setModalState({
                               type: "lab",
                               mode: "edit",
                               created_at: dashboardLabData[0].created_at ? dashboardLabData[0].created_at.slice(0,10) : "-",
                               updated_at: dashboardLabData[0].updated_at ? dashboardLabData[0].updated_at.slice(0,10) : "-",
                               posted_at: dashboardLabData[0].posted_at ? dashboardLabData[0].posted_at.slice(0,10) : "-",
                               metadata_id: dashboardLabData[0].metadata_id
                             })
                           }}>
                        {dashboardLabData.length > 0
                            ? "수정"
                            : ""}
                      </div>
                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             setLabDate(customYMD(Date.now()));
                             setLabNumData({});
                             setLabClassData({});
                             setModalState({
                               type: "lab",
                               mode: "new"
                             })
                           }}>
                        신규등록
                      </div>
                    </div>



                    <div className={cn('dashboardTableRow2')}>
                      <div>SCORAD</div>
                      <div style={{whiteSpace: 'pre-wrap'}}>
                        {dashboardScoradData.length > 0
                            ? `Extent : ${dashboardScoradData[0].response_extent}
Intensity : ${dashboardScoradData[0].response_intensity}
Subjective symptoms : ${dashboardScoradData[0].response_subj_symptoms}
Total score : ${dashboardScoradData[0].total_score}`
                            : "없음"}
                      </div>
                      <div>
                        {dashboardScoradData.length > 0
                            ? dashboardScoradData[0].created_at.slice(0,10)
                            : ""}
                      </div>
                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             LoadDetailScoradData(dashboardScoradData[0])
                             setModalState({
                               type: "scorad",
                               mode: "edit",
                               created_at: dashboardScoradData[0].created_at ? dashboardScoradData[0].created_at.slice(0,10) : "-",
                               updated_at: dashboardScoradData[0].updated_at ? dashboardScoradData[0].updated_at.slice(0,10) : "-",
                               posted_at: dashboardScoradData[0].posted_at ? dashboardScoradData[0].posted_at.slice(0,10) : "-",
                               metadata_id: dashboardScoradData[0].metadata_id
                             })
                           }}>
                        {dashboardScoradData.length > 0
                            ? "수정"
                            : ""}
                      </div>
                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             setScoradData({
                               entered_date: customYMD(Date.now()),
                               response_extent: 0,
                               response_intensity: 0,
                               response_subj_symptoms: 0,
                               total_score: 0
                             });
                             setModalState({
                               type: "scorad",
                               mode: "new"
                             })
                           }}>
                        신규등록
                      </div>
                    </div>



                    <div className={cn('dashboardTableRow1')}>
                      <div>EASI</div>
                      <div>
                        {dashboardEasiData.length > 0
                            ? dashboardEasiData[0].response_easi
                            : "없음"}
                      </div>
                      <div>
                        {dashboardEasiData.length > 0
                            ? dashboardEasiData[0].created_at.slice(0,10)
                            : ""}
                      </div>
                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             LoadDetailEasiData(dashboardEasiData[0])
                             setModalState({
                               type: "easi",
                               mode: "edit",
                               created_at: dashboardEasiData[0].created_at ? dashboardEasiData[0].created_at.slice(0,10) : "-",
                               updated_at: dashboardEasiData[0].updated_at ? dashboardEasiData[0].updated_at.slice(0,10) : "-",
                               posted_at: dashboardEasiData[0].posted_at ? dashboardEasiData[0].posted_at.slice(0,10) : "-",
                               metadata_id: dashboardEasiData[0].metadata_id
                             })
                           }}>
                        {dashboardEasiData.length > 0
                            ? "수정"
                            : ""}
                      </div>
                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             setEasiData({
                               entered_date: customYMD(Date.now()),
                               response_easi: 0
                             });
                             setModalState({
                               type: "easi",
                               mode: "new"
                             })
                           }}>
                        신규등록
                      </div>
                    </div>



                    <div className={cn('dashboardTableRow2')}>
                      <div>부작용</div>
                      <div>
                        {dashboardAdrData.length > 0
                            ? dashboardAdrData[0].response_adr
                            : "없음"}
                      </div>
                      <div>
                        {dashboardAdrData.length > 0
                            ? dashboardAdrData[0].created_at.slice(0,10)
                            : ""}
                      </div>
                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             LoadDetailAdrData(dashboardAdrData[0])
                             setModalState({
                               type: "adr",
                               mode: "edit",
                               created_at: dashboardAdrData[0].created_at ? dashboardAdrData[0].created_at.slice(0,10) : "-",
                               updated_at: dashboardAdrData[0].updated_at ? dashboardAdrData[0].updated_at.slice(0,10) : "-",
                               posted_at: dashboardAdrData[0].posted_at ? dashboardAdrData[0].posted_at.slice(0,10) : "-",
                               metadata_id: dashboardAdrData[0].metadata_id
                             })
                           }}>
                        {dashboardAdrData.length > 0
                            ? "수정"
                            : ""}
                      </div>
                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             setAdrData({
                               entered_date: customYMD(Date.now()),
                               response_adr: ""
                             });
                             setModalState({
                               type: "adr",
                               mode: "new"
                             })
                           }}>
                        신규등록
                      </div>
                    </div>



                    <div className={cn('dashboardTableRow1')}>
                      <div>구강알레르기 증후군</div>
                      <div>
                        {dashboardOasData.length > 0
                            ? Object.entries(dashboardOasData[0]).map((element: any, idx: number) => {
                              let [key, value] = element;
                              if (key === "existence_of_oas") return "증후군 여부: " + (value===false?"No":"Yes - 유발식품: ");
                              if (key.charAt(0) === "r" && value !== null) return value + ", ";
                              return;
                            })
                            : "없음"}
                      </div>
                      <div>
                        {dashboardOasData.length > 0
                            ? dashboardOasData[0].created_at.slice(0,10)
                            : ""}
                      </div>
                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             LoadDetailOasData(dashboardOasData[0])
                             setModalState({
                               type: "oas",
                               mode: "edit",
                               created_at: dashboardOasData[0].created_at ? dashboardOasData[0].created_at.slice(0,10) : "-",
                               updated_at: dashboardOasData[0].updated_at ? dashboardOasData[0].updated_at.slice(0,10) : "-",
                               posted_at: dashboardOasData[0].posted_at ? dashboardOasData[0].posted_at.slice(0,10) : "-",
                               metadata_id: dashboardOasData[0].metadata_id
                             })
                           }}>
                        {dashboardOasData.length > 0
                            ? "수정"
                            : ""}
                      </div>
                      <div style={{color: '#3A57E8', cursor: 'pointer', textAlign: 'center'}}
                           onClick={() => {
                             setOasData({
                               entered_date: customYMD(Date.now()),
                             });
                             setModalState({
                               type: "oas",
                               mode: "new"
                             })
                           }}>
                        신규등록
                      </div>
                    </div>
                  </div>



                </div> : null}


            {/* 설문결과 paper */}
            { pageState.tab === "survey" ?
                <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>
                  {listData.map((item, idx) => {
                    return (
                        <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', gridTemplateColumns: '1.2fr 0.7fr 0.8fr 0.6fr 0.7fr 4fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E'}}>
                          <div style={{fontWeight: 600, fontSize: '16px', color: 'black'}}>{item.surveyed_at.slice(0,10)}</div>
                          <div>ADCT : {item.adct_score}</div>
                          <div>DFI : {item.dfi_score}</div>
                          <div>POEM : {item.poem_score}</div>
                          <div style={{fontSize: '13px'}}>치료부작용 : {getAdrString(item) ?? "없음"}</div>
                          <div>
                            <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      LoadDetailSurveyData(item);
                                      setModalState({
                                        type: "survey",
                                        mode: "detail",
                                        created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                                        updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                                        posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-"
                                      });
                                    }}>
                              상세 보기
                            </button>
                          </div>
                        </div>
                    )})}

                  <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
                    <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === 1) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page-1
                           }))
                         }}/>

                    {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
                      return (
                          <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                               onClick={() => {
                                 setPageState(prevState => ({
                                   ...prevState,
                                   page: i+1
                                 }))
                               }}>
                            {i+1}
                          </div>
                      )})}

                    <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page+1
                           }))
                         }}/>

                  </div>

                </div> : null}


            {/* Lab paper */}
            { pageState.tab === "lab" ?
                <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>

                  <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', gridTemplateColumns: '120px 120px 180px 1fr 110px 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
                    <div>날짜</div>
                    <div>등록일</div>
                    <div>등록된 항목 수</div>
                    <div></div>
                    <div></div>
                    <div>
                      <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setLabDate(customYMD(Date.now()));
                                setLabType("");
                                setLabNumData({});
                                setLabClassData({});
                                setModalState({
                                  type: "lab",
                                  mode: "new"
                                })
                              }}>
                        신규 등록
                      </button>
                    </div>
                  </div>

                  {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
                    return (
                        <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid', gridTemplateColumns: '120px 120px 180px 1fr 110px 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                          {/* <div style={{fontSize: '15px', fontWeight: 700}}>{item.posted_at ? item.posted_at.slice(0,10) : "-"}</div> */}
                          <div>{item.entered_date}</div>
                          <div>{item.created_at.slice(0,10)}</div>
                          <div>{countEnteredLabValues(item)}</div>
                          <div></div>
                          <div>
                            {item.is_posted ?
                                <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                        onClick={() => setDialogState({
                                          isOpen: true,
                                          title: "Lab 게시를 취소하시겠습니까?",
                                          desc: "게시 취소를 원하실 경우 게시 취소 버튼을 눌러주세요.\n더 이상 환자가 Lab 결과 화면을 조회할 수 없게 됩니다.",
                                          accept_label: "게시 취소",
                                          deny_label: "닫기",
                                          accept_callback: () => {
                                            changeLabPosted(item.metadata_id, false)
                                          }
                                        })}>
                                  게시 취소
                                </button> : null}
                          </div>
                          <div>
                            <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      LoadDetailLabData(item);
                                      setModalState({
                                        type: "lab",
                                        mode: "detail",
                                        created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                                        updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                                        posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                                        metadata_id: item.metadata_id
                                      });
                                    }}>
                              상세 보기
                            </button>
                          </div>
                          <div>
                            <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      setDialogState({
                                        isOpen: true,
                                        title: "해당 Lab 데이터를 삭제하시겠습니까?",
                                        desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                                        accept_label: "삭제",
                                        deny_label: "닫기",
                                        accept_callback: () => {
                                          deleteLabData(item.metadata_id)
                                        }
                                      })
                                    }}>
                              삭제
                            </button>
                          </div>
                        </div>
                    )})}

                  <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
                    <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === 1) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page-1
                           }))
                         }}/>

                    {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
                      return (
                          <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                               onClick={() => {
                                 setPageState(prevState => ({
                                   ...prevState,
                                   page: i+1
                                 }))
                               }}>
                            {i+1}
                          </div>
                      )})}

                    <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page+1
                           }))
                         }}/>

                  </div>

                </div> : null}


            {/* SCORAD paper */}
            { pageState.tab === "scorad" ?
                <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>

                  <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid',
                    gridTemplateColumns: '120px 120px 90px 90px 180px 90px 1fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
                    <div>날짜</div>
                    <div>등록일</div>
                    <div>Extent</div>
                    <div>Intensity</div>
                    <div>Subjective symptoms</div>
                    <div>Total</div>
                    <div></div>
                    <div>
                      <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setScoradData({
                                  entered_date: customYMD(Date.now()),
                                  response_extent: 0,
                                  response_intensity: 0,
                                  response_subj_symptoms: 0,
                                  total_score: 0
                                });
                                setModalState({
                                  type: "scorad",
                                  mode: "new"
                                })
                              }}>
                        신규 등록
                      </button>
                    </div>
                  </div>

                  {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
                    return (
                        <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid',
                          gridTemplateColumns: '120px 120px 90px 90px 180px 90px 1fr 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                          <div>{item.entered_date}</div>
                          <div>{item.created_at.slice(0,10)}</div>
                          <div>{item.response_extent}</div>
                          <div>{item.response_intensity}</div>
                          <div>{item.response_subj_symptoms}</div>
                          <div>{item.total_score}</div>
                          <div/>
                          <div>
                            <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      LoadDetailScoradData(item);
                                      setModalState({
                                        type: "scorad",
                                        mode: "detail",
                                        created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                                        updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                                        posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                                        metadata_id: item.metadata_id
                                      });
                                    }}>
                              상세 보기
                            </button>
                          </div>
                          <div>
                            <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      setDialogState({
                                        isOpen: true,
                                        title: "해당 SCORAD 데이터를 삭제하시겠습니까?",
                                        desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                                        accept_label: "삭제",
                                        deny_label: "닫기",
                                        accept_callback: () => {
                                          deleteScoradData(item.metadata_id)
                                        }
                                      })
                                    }}>
                              삭제
                            </button>
                          </div>
                        </div>
                    )})}

                  <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
                    <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === 1) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page-1
                           }))
                         }}/>

                    {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
                      return (
                          <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                               onClick={() => {
                                 setPageState(prevState => ({
                                   ...prevState,
                                   page: i+1
                                 }))
                               }}>
                            {i+1}
                          </div>
                      )})}

                    <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page+1
                           }))
                         }}/>

                  </div>

                </div> : null}


            {/* EASI paper */}
            { pageState.tab === "easi" ?
                <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>

                  <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid',
                    gridTemplateColumns: '120px 120px 120px 1fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
                    <div>날짜</div>
                    <div>등록일</div>
                    <div style={{textAlign: 'left', marginLeft: 50}}>EASI</div>
                    <div></div>
                    <div>
                      <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setEasiData({
                                  entered_date: customYMD(Date.now()),
                                  response_easi: 0
                                });
                                setModalState({
                                  type: "easi",
                                  mode: "new"
                                })
                              }}>
                        신규 등록
                      </button>
                    </div>
                  </div>

                  {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
                    return (
                        <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid',
                          gridTemplateColumns: '120px 120px 120px 1fr 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                          <div>{item.entered_date}</div>
                          <div>{item.created_at.slice(0,10) ?? ""}</div>
                          <div style={{textAlign: 'left', marginLeft: 50}}>{item.response_easi}</div>
                          <div></div>
                          <div>
                            <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      LoadDetailEasiData(item);
                                      setModalState({
                                        type: "easi",
                                        mode: "detail",
                                        created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                                        updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                                        posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                                        metadata_id: item.metadata_id
                                      });
                                    }}>
                              상세 보기
                            </button>
                          </div>
                          <div>
                            <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      setDialogState({
                                        isOpen: true,
                                        title: "해당 EASI 데이터를 삭제하시겠습니까?",
                                        desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                                        accept_label: "삭제",
                                        deny_label: "닫기",
                                        accept_callback: () => {
                                          deleteEasiData(item.metadata_id)
                                        }
                                      })
                                    }}>
                              삭제
                            </button>
                          </div>
                        </div>
                    )})}

                  <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
                    <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === 1) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page-1
                           }))
                         }}/>

                    {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
                      return (
                          <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                               onClick={() => {
                                 setPageState(prevState => ({
                                   ...prevState,
                                   page: i+1
                                 }))
                               }}>
                            {i+1}
                          </div>
                      )})}

                    <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page+1
                           }))
                         }}/>

                  </div>

                </div> : null}


            {/* ADR paper */}
            { pageState.tab === "adr" ?
                <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>
                  <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid',
                    gridTemplateColumns: '120px 120px 1fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
                    <div>날짜</div>
                    <div>등록일</div>
                    <div style={{textAlign: 'left', marginLeft: 50}}>부작용</div>
                    <div>
                      <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setAdrData({
                                  entered_date: customYMD(Date.now()),
                                  response_adr: ""
                                });
                                setModalState({
                                  type: "adr",
                                  mode: "new"
                                })
                              }}>
                        신규 등록
                      </button>
                    </div>
                  </div>

                  {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
                    return (
                        <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid',
                          gridTemplateColumns: '120px 120px 1fr 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                          <div>{item.entered_date}</div>
                          <div>{item.created_at.slice(0,10)}</div>
                          <div style={{textAlign: 'left', marginLeft: 50}}>{item.response_adr}</div>
                          <div>
                            <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      LoadDetailAdrData(item);
                                      setModalState({
                                        type: "adr",
                                        mode: "detail",
                                        created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                                        updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                                        posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                                        metadata_id: item.metadata_id
                                      });
                                    }}>
                              상세 보기
                            </button>
                          </div>
                          <div>
                            <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      setDialogState({
                                        isOpen: true,
                                        title: "해당 부작용 데이터를 삭제하시겠습니까?",
                                        desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                                        accept_label: "삭제",
                                        deny_label: "닫기",
                                        accept_callback: () => {
                                          deleteAdrData(item.metadata_id)
                                        }
                                      })
                                    }}>
                              삭제
                            </button>
                          </div>
                        </div>
                    )})}

                  <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
                    <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === 1) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page-1
                           }))
                         }}/>

                    {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
                      return (
                          <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                               onClick={() => {
                                 setPageState(prevState => ({
                                   ...prevState,
                                   page: i+1
                                 }))
                               }}>
                            {i+1}
                          </div>
                      )})}

                    <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page+1
                           }))
                         }}/>

                  </div>

                </div> : null}


            {/* OAS paper */}
            { pageState.tab === "oas" ?
                <div style={{position: 'relative', width: '94%', height: '75%', top: -115, marginLeft: 40, overflow: 'auto'}}>
                  <div style={{padding: '0 15px', height: 48, background: '#54595E19', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid',
                    gridTemplateColumns: '120px 120px 100px 1fr 110px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 700, textAlign: 'center', gap: 10}}>
                    <div>날짜</div>
                    <div>등록일</div>
                    <div>증후군 여부</div>
                    <div style={{textAlign: 'left', marginLeft: 40}}>유발 식품</div>
                    <div>
                      <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setOasData({
                                  entered_date: customYMD(Date.now()),
                                });
                                setModalState({
                                  type: "oas",
                                  mode: "new"
                                })
                              }}>
                        신규 등록
                      </button>
                    </div>
                  </div>

                  {listData.sort((a, b) => ( b.entered_date < a.entered_date ) ? -1 : ( b.entered_date == a.entered_date ) ? 0 : 1).slice((pageState.page-1) * pageState.max_per_page, (pageState.page)*pageState.max_per_page).map((item, idx) => {
                    return (
                        <div style={{padding: '0 15px', height: 48, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, display: 'grid',
                          gridTemplateColumns: '120px 120px 100px 1fr 110px 60px', alignItems: 'center', fontSize: '14px', color: '#54595E', fontWeight: 400, textAlign: 'center', gap: 10}}>
                          <div>{item.entered_date}</div>
                          <div>{item.created_at.slice(0,10)}</div>
                          <div>{item.existence_of_oas ? "O" : "X"}</div>
                          <div style={{textAlign: 'left', marginLeft: 40}}>
                            {getOasString(item)}
                          </div>
                          <div>
                            <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      LoadDetailOasData(item);
                                      setModalState({
                                        type: "oas",
                                        mode: "detail",
                                        created_at: item.created_at ? item.created_at.slice(0,10) : "-",
                                        updated_at: item.updated_at ? item.updated_at.slice(0,10) : "-",
                                        posted_at: item.posted_at ? item.posted_at.slice(0,10) : "-",
                                        metadata_id: item.metadata_id
                                      });
                                    }}>
                              상세 보기
                            </button>
                          </div>
                          <div>
                            <button style={{width: 60, height: 36, border: 'none', background: '#FF6969', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                    onClick={() => {
                                      setDialogState({
                                        isOpen: true,
                                        title: "해당 구강알레르기 증후군 데이터를 삭제하시겠습니까?",
                                        desc: "삭제를 원하실 경우 삭제 버튼을 눌러주세요.\n삭제 후 복구할 수 없습니다.",
                                        accept_label: "삭제",
                                        deny_label: "닫기",
                                        accept_callback: () => {
                                          deleteOasData(item.metadata_id)
                                        }
                                      })
                                    }}>
                              삭제
                            </button>
                          </div>
                        </div>
                    )})}

                  <div style={{display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%', height: 40, position: 'absolute', bottom: 100}}>
                    <img style={{width: 24, marginRight: 5, cursor: 'pointer'}} src={pageState.page !== 1 ? `./img/ic_arrow_left_enabled.png` : `./img/ic_arrow_left_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === 1) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page-1
                           }))
                         }}/>

                    {Array.apply(null, new Array(pageState.total_page)).map((v, i) => {
                      return (
                          <div className={cn({'pageButton': true, 'pageButtonSelected': pageState.page === i+1})}
                               onClick={() => {
                                 setPageState(prevState => ({
                                   ...prevState,
                                   page: i+1
                                 }))
                               }}>
                            {i+1}
                          </div>
                      )})}

                    <img style={{width: 24, marginLeft: 5, cursor: 'pointer'}} src={pageState.page !== pageState.total_page && pageState.total_page !== 0 ? `./img/ic_arrow_right_enabled.png` : `./img/ic_arrow_right_disabled.png`} alt="user icon"
                         onClick={() => {
                           if (pageState.page === pageState.total_page || pageState.total_page === 0) return;
                           setPageState(prevState => ({
                             ...prevState,
                             page: prevState.page+1
                           }))
                         }}/>

                  </div>

                </div> : null}

          </div>
        </div>



        <div style={{display: 'flex', position: 'sticky', width: '100%', height: 40, bottom: 0, background: 'white', alignItems: 'center', borderTop: '1px solid #E9ECEF'}}>
          <div style={{fontSize: '12px', marginLeft: '15px'}}>E-CRF 계정 및 오류 문의 | mdpp_support@evidnet.co.kr</div>
          <div style={{fontSize: '12px', marginLeft: 'auto', marginRight: '15px', userSelect: 'none'}}>© 2023. evidnet Inc.</div>
        </div>

        {modalState.type === "lab" ?
            <div className={cn('modalBase')} style={{minHeight: 720}}>
              <div className={cn('modalPaper')} style={{height: 690, padding: 20}}>
                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>

                  {modalState.mode === "detail" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>Lab(알레르기 검사 결과)</div>
                        <button style={{width: 110, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                                onClick={() => setDialogState({
                                  isOpen: true,
                                  title: "Lab 결과를 게시하시겠습니까?",
                                  desc: "환자가 메디팡팡 앱을 통해 Lab 결과를 조회할 수 있게 됩니다.\n환자의 Lab 결과 조회 화면을 확인하기 위해서는 게시 미리보기 버튼을 눌러주세요.",
                                  accept_label: "게시",
                                  deny_label: "취소",
                                  accept_callback: () => {
                                    changeLabPosted(modalState.metadata_id, true)
                                  }
                                })}>
                          게시
                        </button>
                        <button style={{width: 110, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                                onClick={() => {
                                  setModalState({
                                    ...modalState,
                                    type: 'lab_view'
                                  })
                                }}>
                          게시 미리보기
                        </button>
                      </div>: null}

                  {modalState.mode === "new" || modalState.mode === "edit" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>Lab(알레르기 검사 결과) {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
                      </div>: null}

                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         if (modalState.isChanged === true) {
                           setDialogState({
                             isOpen: true,
                             title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                             desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                             accept_label: "닫기",
                             deny_label: "취소",
                             accept_callback: () => {
                               setModalState({
                                 type: "",
                                 mode: ""
                               })
                               setDialogState({
                                 isOpen: false
                               })
                             }
                           })
                         }
                         else {
                           setModalState({
                             type: "",
                             mode: ""
                           })
                         }
                       }}/>
                </div>

                <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
                  <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
                  <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>

                  {modalState.mode === "detail" || modalState.mode === "edit" ?
                      <div style={{fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto', textAlign: 'left'}}>
                        최초등록 {modalState.created_at}<br/>
                        업데이트 {modalState.updated_at}<br/>
                        게시일 {modalState.posted_at}<br/>
                      </div> : null}
                </div>

                <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
                  <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
                  <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
                </div>

                <div style={{ marginTop: 5, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
                  {"- 수치 및 Class 직접 입력"}<br/>
                  {"- 수치( IU/ML) : 00.00 (Max: 1000  /  Min: 0)"}<br/>
                  {/* {"- MAST, IMMUNOCAP 구분 없이 입력"}<br/> */}
                </div>

                <div style={{width: '100%', height: 440, marginTop: 10, fontSize: '12px', textAlign: 'center', display: 'flex', overflowY: 'scroll', borderRadius: '4px', border: '1px solid #A2A2A2'}}>

                  <div style={{width: '50%', borderTopLeftRadius: '4px'}}>
                    <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.1)'}}>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>날짜</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>
                        <input
                            type='text'
                            maxLength={10}
                            className={cn('cellTextInput')}
                            style={{textAlign: 'center'}}
                            defaultValue={labDate}
                            disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                            onKeyUp={(e) => {
                              const value = e.currentTarget.value;
                              const s = e.currentTarget.selectionStart;

                              if (s === null) return;
                              if (isNaN(Number(e.key))) return;

                              if (value.length === 5 && !value.endsWith('-')) {
                                console.log('here4')
                                e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                              }
                              else if (value.length === 8 && !value.endsWith('-')) {
                                console.log('here5')
                                e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                              }
                              else if (value.length === 6 && !value.includes('-')) {
                                console.log('here6')
                                e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                              }
                              else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                                console.log('here7 position: '+s)
                                e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                                e.currentTarget.selectionEnd = s+1;
                              }
                              setLabDate(e.currentTarget.value);

                            }}
                            onKeyDown={(e) => {
                              if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                                const s = e.currentTarget.selectionStart;
                                const value = e.currentTarget.value;
                                console.log(`selection: ${s} / key: ${e.key}`)

                                if (s === null) return;

                                if (s === 4 || s === 7) {
                                  if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                                    e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                  }
                                  else {
                                    e.preventDefault();
                                    e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                    e.currentTarget.selectionStart = s+2;
                                  }
                                  e.currentTarget.selectionEnd = s+2;
                                }
                                else if (s !== 10) {
                                  console.log('here2')
                                  e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                                  e.currentTarget.selectionEnd = s+1;
                                }
                              }
                            }}
                            onChange={(e) => {
                              setLabDate(e.target.value);
                              setModalState({
                                ...modalState,
                                isChanged: true
                              })
                            }}>
                        </input>
                      </div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}></div>
                    </div>
                    <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.05)'}}>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>항목</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>수치</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>Class</div>
                    </div>


                    {Object.keys(LAB_ITEMS_COLUMN_1).sort().map((item, idx) => {
                      return (
                          <div className={cn('tableRow')}>
                            <div className={cn('tableCell')}>{LAB_ITEMS_COLUMN_1[item]}</div>
                            <div className={cn('tableCell')}>
                              <input
                                  type='number'
                                  maxLength={5}
                                  style={{ width: 100, height: 20, outline: 'none', fontSize: '12px', fontWeight: '600', lineHeight: '12px', letterSpacing:'-0.5px', boxSizing: 'border-box', border: 'none'}}
                                  onChange={(e) => {
                                    setLabNumData(prevState => ({
                                      ...prevState,
                                      [item]:  e.target.value
                                    }))
                                    setModalState({
                                      ...modalState,
                                      isChanged: true
                                    })
                                  }}
                                  step="0.01"
                                  max={1000}
                                  min={0}
                                  defaultValue={getLabValue(item)}
                                  disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                              >
                              </input>
                            </div>
                            <div className={cn('tableCell')}>
                              <input
                                  type='text'
                                  maxLength={10}
                                  className={cn('cellTextInput')}
                                  onChange={(e) => {
                                    setLabClassData(prevState => ({
                                      ...prevState,
                                      [item]:  e.target.value
                                    }))
                                    setModalState({
                                      ...modalState,
                                      isChanged: true
                                    })
                                  }}
                                  defaultValue={getLabClass(item)}
                                  disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                              >
                              </input>
                            </div>
                          </div>
                      )
                    })}
                  </div>

                  <div style={{width: '50%', borderTopLeftRadius: '4px'}}>
                    <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.1)'}}>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>검사 방법</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>
                        <select className={cn('cellSelect')} name="labType" id="labTypeSelect" value={labType} disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                                onChange={(e) => {
                                  console.log("here change labtype : "+e.target.value)
                                  setLabType(e.target.value)
                                }}>
                          <option value="" disabled selected>선택</option>
                          <option value="MAST">MAST</option>
                          <option value="IMMUNOCAP">IMMUNOCAP</option>
                        </select>
                      </div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}></div>
                    </div>
                    <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.05)'}}>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>항목</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>수치</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>Class</div>
                    </div>


                    {Object.keys(LAB_ITEMS_COLUMN_2).sort().map((item, idx) => {
                      return (
                          <div className={cn('tableRow')}>
                            <div className={cn('tableCell')}>{LAB_ITEMS_COLUMN_2[item]}</div>
                            <div className={cn('tableCell')}>
                              <input
                                  type='number'
                                  maxLength={5}
                                  style={{ width: 100, height: 20, outline: 'none', fontSize: '12px', fontWeight: '600', lineHeight: '12px', letterSpacing:'-0.5px', boxSizing: 'border-box', border: 'none'}}
                                  onChange={(e) => {
                                    setLabNumData(prevState => ({
                                      ...prevState,
                                      [item]:  e.target.value
                                    }))
                                    setModalState({
                                      ...modalState,
                                      isChanged: true
                                    })
                                  }}
                                  step="0.01"
                                  max={1000}
                                  min={0}
                                  defaultValue={getLabValue(item)}
                                  disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                              >
                              </input>
                            </div>
                            <div className={cn('tableCell')}>
                              <input
                                  type='text'
                                  maxLength={10}
                                  className={cn('cellTextInput')}
                                  onChange={(e) => {
                                    setLabClassData(prevState => ({
                                      ...prevState,
                                      [item]:  e.target.value
                                    }))
                                    setModalState({
                                      ...modalState,
                                      isChanged: true
                                    })
                                  }}
                                  defaultValue={getLabClass(item)}
                                  disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                              >
                              </input>
                            </div>
                          </div>
                      )
                    })}
                  </div>

                </div>

                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>

                  {modalState.mode === "new"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertLabData(0);
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "edit"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertLabData(modalState.metadata_id);
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "detail" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                setModalState({
                                  ...modalState,
                                  mode: 'edit'
                                })
                              }}>
                        수정
                      </button> : null}

                  {modalState.mode === "detail" || modalState.mode === "new" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setModalState({
                                  type: "",
                                  mode: ""
                                })
                              }}>
                        닫기
                      </button> :  null}

                  {modalState.mode === "edit" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                if (modalState.isChanged === true) {
                                  setDialogState({
                                    isOpen: true,
                                    title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                                    desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                                    accept_label: "닫기",
                                    deny_label: "취소",
                                    accept_callback: () => {
                                      setModalState({
                                        ...modalState,
                                        type: "",
                                        mode: ""
                                      })
                                      ReloadDetailLabData();
                                      setTimeout(() => {
                                        setModalState({
                                          ...modalState,
                                          mode: "detail",
                                          isChanged: false,
                                        })
                                        setDialogState({
                                          isOpen: false
                                        })

                                      }, 100)

                                    }
                                  })
                                }
                                else {
                                  setModalState({
                                    ...modalState,
                                    mode: "detail"
                                  })
                                }
                              }}>
                        뒤로
                      </button> :  null}
                </div>

              </div>
            </div>
            : null}

        {modalState.type === "lab_view" ?
            <div className={cn('modalBase')} style={{minHeight: 720}}>
              <div className={cn('modalPaper')} style={{height: 690, padding: 20}}>
                <div style={{}}>
                  <img style={{height: 180, width: '100%', objectFit: 'cover', borderRadius: '7px 7px 16px 16px'}} src={`./img/modal_view_bg.svg`} alt="dashboard background"/>
                  <div style={{position: 'relative', fontSize: '28px', fontWeight: '700', marginLeft: 30, top: -160, color: 'white'}}>
                    {pageState.hospital}
                  </div>
                </div>

                <div style={{display: 'flex', width: 'calc(100% - 100px)', marginTop: -130, alignItems: 'center', alignSelf: 'center', height: 60, padding: '0 20px', background: 'white', borderRadius: '8px', gap: 10}}>
                  <div style={{fontSize: '24px', fontWeight: '700'}}>{markName(participantInfo.participant_name)}</div>
                  <div style={{fontSize: '16px', fontWeight: '400', color: '#232D42', marginTop: 5}}>| 연구 등록 번호 {participantInfo.participant_id}</div>
                </div>


                <div style={{position: 'relative', display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10, top: -130, left: -15}}>
                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         setModalState({
                           ...modalState,
                           type: "lab",
                           mode: "detail"
                         })
                       }}/>
                </div>


                <div style={{width: '100%', height: 440, marginTop: 10, fontSize: '12px', textAlign: 'center', display: 'flex', overflowY: 'scroll', borderRadius: '4px', border: '1px solid #A2A2A2', alignSelf: 'center'}}>

                  <div style={{width: '50%', borderTopLeftRadius: '4px'}}>
                    <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.1)'}}>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>날짜</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>
                        <input
                            type='text'
                            maxLength={10}
                            className={cn('cellTextInput')}
                            style={{textAlign: 'center'}}
                            defaultValue={labDate}
                            disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                            onKeyUp={(e) => {
                              const value = e.currentTarget.value;
                              const s = e.currentTarget.selectionStart;

                              if (s === null) return;
                              if (isNaN(Number(e.key))) return;

                              if (value.length === 5 && !value.endsWith('-')) {
                                console.log('here4')
                                e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                              }
                              else if (value.length === 8 && !value.endsWith('-')) {
                                console.log('here5')
                                e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                              }
                              else if (value.length === 6 && !value.includes('-')) {
                                console.log('here6')
                                e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                              }
                              else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                                console.log('here7 position: '+s)
                                e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                                e.currentTarget.selectionEnd = s+1;
                              }

                            }}
                            onKeyDown={(e) => {
                              if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                                const s = e.currentTarget.selectionStart;
                                const value = e.currentTarget.value;
                                console.log(`selection: ${s} / key: ${e.key}`)

                                if (s === null) return;

                                if (s === 4 || s === 7) {
                                  if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                                    e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                  }
                                  else {
                                    e.preventDefault();
                                    e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                    e.currentTarget.selectionStart = s+2;
                                  }
                                  e.currentTarget.selectionEnd = s+2;
                                }
                                else if (s !== 10) {
                                  console.log('here2')
                                  e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                                  e.currentTarget.selectionEnd = s+1;
                                }
                              }
                            }}
                            onChange={(e) => {
                              setLabDate(e.target.value);
                            }}>

                        </input>
                      </div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}></div>
                    </div>
                    <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.05)'}}>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>항목</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>수치</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>Class</div>
                    </div>


                    {Object.keys(LAB_ITEMS_COLUMN_1).sort().map((item, idx) => {
                      return (
                          <div className={cn('tableRow')}>
                            <div className={cn('tableCell')}>{LAB_ITEMS_COLUMN_1[item]}</div>
                            <div className={cn('tableCell')}>
                              <input
                                  type='number'
                                  maxLength={5}
                                  style={{ width: 100, height: 20, outline: 'none', fontSize: '12px', fontWeight: '600', lineHeight: '12px', letterSpacing:'-0.5px', boxSizing: 'border-box', border: 'none'}}
                                  onChange={(e) => {
                                    setLabNumData(prevState => ({
                                      ...prevState,
                                      [item]:  e.target.value
                                    }))
                                  }}
                                  step="0.01"
                                  max={100}
                                  min={0}
                                  defaultValue={getLabValue(item)}
                                  disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                              >
                              </input>
                            </div>
                            <div className={cn('tableCell')}>
                              <input
                                  type='text'
                                  maxLength={10}
                                  className={cn('cellTextInput')}
                                  onChange={(e) => {
                                    setLabClassData(prevState => ({
                                      ...prevState,
                                      [item]:  e.target.value
                                    }))
                                  }}
                                  defaultValue={getLabClass(item)}
                                  disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                              >
                              </input>
                            </div>
                          </div>
                      )
                    })}
                  </div>

                  <div style={{width: '50%', borderTopLeftRadius: '4px'}}>
                    <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.1)'}}>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>검사 방법</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>
                        <select className={cn('cellSelect')} name="labType" id="labTypeSelect" value={labType} disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                                onChange={(e) => {
                                  console.log("here change labtype : "+e.target.value)
                                  setLabType(e.target.value)
                                }}>
                          <option value="" disabled selected>선택</option>
                          <option value="MAST">MAST</option>
                          <option value="IMMUNOCAP">IMMUNOCAP</option>
                        </select>
                      </div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}></div>
                    </div>
                    <div className={cn('tableRow')} style={{background: 'rgba(0, 0, 0, 0.05)'}}>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>항목</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>수치</div>
                      <div className={cn('tableCell')} style={{fontWeight: 700, textAlign: 'center'}}>Class</div>
                    </div>


                    {Object.keys(LAB_ITEMS_COLUMN_2).sort().map((item, idx) => {
                      return (
                          <div className={cn('tableRow')}>
                            <div className={cn('tableCell')}>{LAB_ITEMS_COLUMN_2[item]}</div>
                            <div className={cn('tableCell')}>
                              <input
                                  type='number'
                                  maxLength={5}
                                  style={{ width: 100, height: 20, outline: 'none', fontSize: '12px', fontWeight: '600', lineHeight: '12px', letterSpacing:'-0.5px', boxSizing: 'border-box', border: 'none'}}
                                  onChange={(e) => {
                                    setLabNumData(prevState => ({
                                      ...prevState,
                                      [item]:  e.target.value
                                    }))
                                  }}
                                  step="0.01"
                                  max="100"
                                  min="0"
                                  defaultValue={getLabValue(item)}
                                  disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                              >
                              </input>
                            </div>
                            <div className={cn('tableCell')}>
                              <input
                                  type='text'
                                  maxLength={10}
                                  className={cn('cellTextInput')}
                                  onChange={(e) => {
                                    setLabClassData(prevState => ({
                                      ...prevState,
                                      [item]:  e.target.value
                                    }))
                                  }}
                                  defaultValue={getLabClass(item)}
                                  disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                              >
                              </input>
                            </div>
                          </div>
                      )
                    })}
                  </div>

                </div>

                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>
                  <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                          onClick={() => setDialogState({
                            isOpen: true,
                            title: "Lab 결과를 게시하시겠습니까?",
                            desc: "환자가 메디팡팡 앱을 통해 Lab 결과를 조회할 수 있게 됩니다.\n환자의 Lab 결과 조회 화면을 확인하기 위해서는 게시 미리보기 버튼을 눌러주세요.",
                            accept_label: "게시",
                            deny_label: "취소",
                            accept_callback: () => {
                              changeLabPosted(modalState.metadata_id, true)
                            }
                          })}>
                    게시
                  </button>
                  <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                          onClick={() => {
                            setModalState({
                              type: "",
                              mode: ""
                            })
                          }}>
                    닫기
                  </button>
                </div>

              </div>
            </div>
            : null}

        {modalState.type === "scorad" ?
            <div className={cn('modalBase')} style={{minHeight: 720}}>
              <div className={cn('modalPaper')} style={{height: 420, padding: 20}}>
                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>

                  {modalState.mode === "detail" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>SCORAD</div>
                      </div>: null}

                  {modalState.mode === "new" || modalState.mode === "edit" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>SCORAD {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
                      </div>: null}

                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         if (modalState.isChanged === true) {
                           setDialogState({
                             isOpen: true,
                             title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                             desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                             accept_label: "닫기",
                             deny_label: "취소",
                             accept_callback: () => {
                               setModalState({
                                 type: "",
                                 mode: ""
                               })
                               setDialogState({
                                 isOpen: false
                               })
                             }
                           })
                         }
                         else {
                           setModalState({
                             type: "",
                             mode: ""
                           })
                         }
                       }}/>
                </div>

                <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
                  <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
                  <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>

                  {modalState.mode === "detail" || modalState.mode === "edit" ?
                      <div style={{width: 130, textAlign: 'left', fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto'}}>
                        최초등록 {modalState.created_at}<br/>
                        업데이트 {modalState.updated_at}<br/>
                      </div> : null}
                </div>

                <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
                  <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
                  <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
                </div>

                <div style={{ marginTop: 5, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
                  {"- 연속 변수 직접 입력"}<br/>
                  {"- Max: 103  /  Min: 0"}<br/>
                  {"- 숫자만 입력 가능"}<br/>

                </div>

                <div style={{width: '100%', height: 240, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>

                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>날짜</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={scoradData.entered_date}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          maxLength={10}
                          onKeyUp={(e) => {
                            const value = e.currentTarget.value;
                            const s = e.currentTarget.selectionStart;

                            if (s === null) return;
                            if (isNaN(Number(e.key))) return;

                            if (value.length === 5 && !value.endsWith('-')) {
                              console.log('here4')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                            }
                            else if (value.length === 8 && !value.endsWith('-')) {
                              console.log('here5')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                            }
                            else if (value.length === 6 && !value.includes('-')) {
                              console.log('here6')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                            }
                            else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                              console.log('here7 position: '+s)
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                              e.currentTarget.selectionEnd = s+1;
                            }
                            setScoradData({
                              ...scoradData,
                              entered_date: e.currentTarget.value
                            });

                          }}
                          onKeyDown={(e) => {
                            if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                              const s = e.currentTarget.selectionStart;
                              const value = e.currentTarget.value;
                              console.log(`selection: ${s} / key: ${e.key}`)

                              if (s === null) return;

                              if (s === 4 || s === 7) {
                                if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                                  e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                }
                                else {
                                  e.preventDefault();
                                  e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                  e.currentTarget.selectionStart = s+2;
                                }
                                e.currentTarget.selectionEnd = s+2;
                              }
                              else if (s !== 10) {
                                console.log('here2')
                                e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                                e.currentTarget.selectionEnd = s+1;
                              }
                            }
                          }}
                          onChange={(e) => {
                            setScoradData({
                              ...scoradData,
                              entered_date: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>Extent</div>
                    <div>
                      <input
                          type='number'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={scoradData.response_extent}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          onChange={(e) => {
                            setScoradData({
                              ...scoradData,
                              response_extent: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>

                    <div>Intensity</div>
                    <div>
                      <input
                          type='number'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={scoradData.response_intensity}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          onChange={(e) => {
                            setScoradData({
                              ...scoradData,
                              response_intensity: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>

                    <div style={{marginTop: -5}}>Subjective symptoms</div>
                    <div>
                      <input
                          type='number'
                          maxLength={10}
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={scoradData.response_subj_symptoms}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          onChange={(e) => {
                            setScoradData({
                              ...scoradData,
                              response_subj_symptoms: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: '4fr 1fr 1fr', marginTop: 25, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div/>
                    <div>Total score</div>
                    <div>
                      <input
                          type='number'
                          maxLength={10}
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={scoradData.total_score}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          onChange={(e) => {
                            setScoradData({
                              ...scoradData,
                              total_score: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>
                  </div>


                </div>

                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>

                  {modalState.mode === "new"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertScoradData(0);
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "edit"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertScoradData(modalState.metadata_id);
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "detail" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                setModalState({
                                  ...modalState,
                                  mode: 'edit'
                                })
                              }}>
                        수정
                      </button> : null}

                  {modalState.mode === "detail" || modalState.mode === "new" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setModalState({
                                  type: "",
                                  mode: ""
                                })
                              }}>
                        닫기
                      </button> :  null}

                  {modalState.mode === "edit"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                if (modalState.isChanged === true) {
                                  setDialogState({
                                    isOpen: true,
                                    title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                                    desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                                    accept_label: "닫기",
                                    deny_label: "취소",
                                    accept_callback: () => {
                                      setModalState({
                                        ...modalState,
                                        type: "",
                                        mode: ""
                                      })
                                      ReloadDetailScoradData();
                                      setTimeout(() => {
                                        setModalState({
                                          ...modalState,
                                          mode: "detail",
                                          isChanged: false,
                                        })
                                        setDialogState({
                                          isOpen: false
                                        })

                                      }, 100)

                                    }
                                  })
                                }
                                else {
                                  setModalState({
                                    ...modalState,
                                    mode: "detail"
                                  })
                                }
                              }}>
                        뒤로
                      </button> :  null}
                </div>

              </div>
            </div>
            : null}

        {modalState.type === "easi" ?
            <div className={cn('modalBase')} style={{minHeight: 720}}>
              <div className={cn('modalPaper')} style={{height: 360, padding: 20}}>
                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>

                  {modalState.mode === "detail" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>EASI</div>
                      </div>: null}

                  {modalState.mode === "new" || modalState.mode === "edit" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>EASI {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
                      </div>: null}

                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         if (modalState.isChanged === true) {
                           setDialogState({
                             isOpen: true,
                             title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                             desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                             accept_label: "닫기",
                             deny_label: "취소",
                             accept_callback: () => {
                               setModalState({
                                 type: "",
                                 mode: ""
                               })
                               setDialogState({
                                 isOpen: false
                               })
                             }
                           })
                         }
                         else {
                           setModalState({
                             type: "",
                             mode: ""
                           })
                         }
                       }}/>
                </div>

                <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
                  <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
                  <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>

                  {modalState.mode === "detail" || modalState.mode === "edit" ?
                      <div style={{width: 130, fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto', textAlign: 'left'}}>
                        최초등록 {modalState.created_at}<br/>
                        업데이트 {modalState.updated_at}<br/>
                      </div> : null}
                </div>

                <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
                  <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
                  <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
                </div>

                <div style={{ marginTop: 5, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
                  {"- 연속 변수 직접 입력"}<br/>
                  {"- Max: 72  /  Min: 0"}<br/>
                  {"- 숫자만 입력 가능"}<br/>

                </div>

                <div style={{width: '100%', height: 240, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>

                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>날짜</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={easiData.entered_date}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          maxLength={10}
                          onKeyUp={(e) => {
                            const value = e.currentTarget.value;
                            const s = e.currentTarget.selectionStart;

                            if (s === null) return;
                            if (isNaN(Number(e.key))) return;

                            if (value.length === 5 && !value.endsWith('-')) {
                              console.log('here4')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                            }
                            else if (value.length === 8 && !value.endsWith('-')) {
                              console.log('here5')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                            }
                            else if (value.length === 6 && !value.includes('-')) {
                              console.log('here6')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                            }
                            else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                              console.log('here7 position: '+s)
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                              e.currentTarget.selectionEnd = s+1;
                            }
                            setEasiData({
                              ...easiData,
                              entered_date: e.currentTarget.value
                            });

                          }}
                          onKeyDown={(e) => {
                            if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                              const s = e.currentTarget.selectionStart;
                              const value = e.currentTarget.value;
                              console.log(`selection: ${s} / key: ${e.key}`)

                              if (s === null) return;

                              if (s === 4 || s === 7) {
                                if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                                  e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                }
                                else {
                                  e.preventDefault();
                                  e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                  e.currentTarget.selectionStart = s+2;
                                }
                                e.currentTarget.selectionEnd = s+2;
                              }
                              else if (s !== 10) {
                                console.log('here2')
                                e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                                e.currentTarget.selectionEnd = s+1;
                              }
                            }
                          }}
                          onChange={(e) => {
                            setEasiData({
                              ...easiData,
                              entered_date: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>EASI</div>
                    <div>
                      <input
                          type='number'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={easiData.response_easi}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          onChange={(e) => {
                            setEasiData({
                              ...easiData,
                              response_easi: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>

                  </div>

                </div>

                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>

                  {modalState.mode === "new"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertEasiData(0)
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "edit"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertEasiData(modalState.metadata_id)
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "detail" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                setModalState({
                                  ...modalState,
                                  mode: 'edit'
                                })
                              }}>
                        수정
                      </button> : null}


                  {modalState.mode === "detail" || modalState.mode === "new" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setModalState({
                                  type: "",
                                  mode: ""
                                })
                              }}>
                        닫기
                      </button> :  null}

                  {modalState.mode === "edit" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                if (modalState.isChanged === true) {
                                  setDialogState({
                                    isOpen: true,
                                    title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                                    desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                                    accept_label: "닫기",
                                    deny_label: "취소",
                                    accept_callback: () => {
                                      setModalState({
                                        ...modalState,
                                        type: "",
                                        mode: ""
                                      })
                                      ReloadDetailEasiData();
                                      setTimeout(() => {
                                        setModalState({
                                          ...modalState,
                                          mode: "detail",
                                          isChanged: false,
                                        })
                                        setDialogState({
                                          isOpen: false
                                        })

                                      }, 100)

                                    }
                                  })
                                }
                                else {
                                  setModalState({
                                    ...modalState,
                                    mode: "detail"
                                  })
                                }
                              }}>
                        뒤로
                      </button> :  null}
                </div>

              </div>
            </div>
            : null}

        {modalState.type === "adr" ?
            <div className={cn('modalBase')} style={{minHeight: 720}}>
              <div className={cn('modalPaper')} style={{height: 420, padding: 20}}>
                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>

                  {modalState.mode === "detail" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>부작용</div>
                      </div>: null}

                  {modalState.mode === "new" || modalState.mode === "edit" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>부작용 {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
                      </div>: null}

                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         if (modalState.isChanged === true) {
                           setDialogState({
                             isOpen: true,
                             title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                             desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                             accept_label: "닫기",
                             deny_label: "취소",
                             accept_callback: () => {
                               setModalState({
                                 type: "",
                                 mode: ""
                               })
                               setDialogState({
                                 isOpen: false
                               })
                             }
                           })
                         }
                         else {
                           setModalState({
                             type: "",
                             mode: ""
                           })
                         }
                       }}/>
                </div>

                <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
                  <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
                  <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>

                  {modalState.mode === "detail" || modalState.mode === "edit" ?
                      <div style={{width: 130, fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto', textAlign: 'left'}}>
                        최초등록 {modalState.created_at}<br/>
                        업데이트 {modalState.updated_at}<br/>
                      </div> : null}
                </div>

                <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
                  <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
                  <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
                </div>

                <div style={{ marginTop: 10, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
                  {"- 영어로 입력"}<br/>

                </div>

                <div style={{width: '100%', height: 240, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>

                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>날짜</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={adrData.entered_date}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          maxLength={10}
                          onKeyUp={(e) => {
                            const value = e.currentTarget.value;
                            const s = e.currentTarget.selectionStart;

                            if (s === null) return;
                            if (isNaN(Number(e.key))) return;

                            if (value.length === 5 && !value.endsWith('-')) {
                              console.log('here4')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                            }
                            else if (value.length === 8 && !value.endsWith('-')) {
                              console.log('here5')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                            }
                            else if (value.length === 6 && !value.includes('-')) {
                              console.log('here6')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                            }
                            else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                              console.log('here7 position: '+s)
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                              e.currentTarget.selectionEnd = s+1;
                            }
                            setAdrData({
                              ...adrData,
                              entered_date: e.currentTarget.value
                            });

                          }}
                          onKeyDown={(e) => {
                            if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                              const s = e.currentTarget.selectionStart;
                              const value = e.currentTarget.value;
                              console.log(`selection: ${s} / key: ${e.key}`)

                              if (s === null) return;

                              if (s === 4 || s === 7) {
                                if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                                  e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                }
                                else {
                                  e.preventDefault();
                                  e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                  e.currentTarget.selectionStart = s+2;
                                }
                                e.currentTarget.selectionEnd = s+2;
                              }
                              else if (s !== 10) {
                                console.log('here2')
                                e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                                e.currentTarget.selectionEnd = s+1;
                              }
                            }
                          }}
                          onChange={(e) => {
                            setAdrData({
                              ...adrData,
                              entered_date: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: '1fr 5fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>부작용</div>
                    <div style={{display: 'flex', marginLeft: 2}}>
                    <textarea
                        className={cn('cellTextInput')}
                        style={{textAlign: 'left', width: 530, height: 50, background: '#F3F3F3', borderRadius: 8, marginTop: -5, padding: 10, resize: 'none', lineHeight: '16px'}}
                        defaultValue={adrData.response_adr}
                        disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                        onChange={(e) => {
                          setAdrData({
                            ...adrData,
                            response_adr: e.target.value
                          });
                          setModalState({
                            ...modalState,
                            isChanged: true
                          })
                        }}>
                    </textarea>
                    </div>
                  </div>



                </div>

                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>

                  {modalState.mode === "new"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertAdrData(0)
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "edit"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertAdrData(modalState.metadata_id)
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "detail" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                setModalState({
                                  ...modalState,
                                  mode: 'edit'
                                })
                              }}>
                        수정
                      </button> : null}

                  {modalState.mode === "detail" || modalState.mode === "new" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setModalState({
                                  type: "",
                                  mode: ""
                                })
                              }}>
                        닫기
                      </button> :  null}

                  {modalState.mode === "edit" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                if (modalState.isChanged === true) {
                                  setDialogState({
                                    isOpen: true,
                                    title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                                    desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                                    accept_label: "닫기",
                                    deny_label: "취소",
                                    accept_callback: () => {
                                      setModalState({
                                        ...modalState,
                                        type: "",
                                        mode: ""
                                      })
                                      ReloadDetailAdrData();
                                      setTimeout(() => {
                                        setModalState({
                                          ...modalState,
                                          mode: "detail",
                                          isChanged: false,
                                        })
                                        setDialogState({
                                          isOpen: false
                                        })

                                      }, 100)

                                    }
                                  })
                                }
                                else {
                                  setModalState({
                                    ...modalState,
                                    mode: "detail"
                                  })
                                }
                              }}>
                        뒤로
                      </button> :  null}
                </div>

              </div>
            </div>
            : null}


        {modalState.type === "oas" ?
            <div className={cn('modalBase')} style={{minHeight: 720}}>
              <div className={cn('modalPaper')} style={{height: 460, padding: 20}}>
                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>

                  {modalState.mode === "detail" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>구강알레르기 증후군</div>
                      </div>: null}

                  {modalState.mode === "new" || modalState.mode === "edit" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>구강알레르기 증후군 {modalState.mode === "new" ? "신규 등록" : "수정"} </div>
                      </div>: null}

                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         if (modalState.isChanged === true) {
                           setDialogState({
                             isOpen: true,
                             title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                             desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                             accept_label: "닫기",
                             deny_label: "취소",
                             accept_callback: () => {
                               setModalState({
                                 type: "",
                                 mode: ""
                               })
                               setDialogState({
                                 isOpen: false
                               })
                             }
                           })
                         }
                         else {
                           setModalState({
                             type: "",
                             mode: ""
                           })
                         }
                       }}/>
                </div>

                <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
                  <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
                  <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>

                  {modalState.mode === "detail" || modalState.mode === "edit" ?
                      <div style={{width: 130, fontSize: '13px', fontWeight: 400, marginTop: 5, marginLeft: 'auto', textAlign: 'left'}}>
                        최초등록 {modalState.created_at}<br/>
                        업데이트 {modalState.updated_at}<br/>
                      </div> : null}
                </div>

                <div style={{display: 'flex', marginTop: 10, height: 24, alignItems: 'center', gap: 10, marginLeft: 10}}>
                  <img style={{width: 24, height: 24, cursor: 'pointer'}} src={`./img/ic_description.png`} alt="description icon"/>
                  <div style={{fontSize: '14px', fontWeight: 700}}>입력 시 참고하세요~</div>
                </div>

                <div style={{ marginTop: 5, marginLeft: 10, fontSize: '12px', fontWeight: 400, color: '#8A92A6', lineHeight: '15px'}}>
                  {"- 유발식품은 한글로 입력"}<br/>
                </div>

                <div style={{width: '100%', height: 230, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>

                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 20, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>날짜</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.entered_date}
                          disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                          maxLength={10}
                          onKeyUp={(e) => {
                            const value = e.currentTarget.value;
                            const s = e.currentTarget.selectionStart;

                            if (s === null) return;
                            if (isNaN(Number(e.key))) return;

                            if (value.length === 5 && !value.endsWith('-')) {
                              console.log('here4')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-1)}`
                            }
                            else if (value.length === 8 && !value.endsWith('-')) {
                              console.log('here5')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(-1)}`
                            }
                            else if (value.length === 6 && !value.includes('-')) {
                              console.log('here6')
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(-2)}`
                            }
                            else if ((value.length === 9 || value.length === 10) && value.charAt(7) !== '-' && s !== 5) {
                              console.log('here7 position: '+s)
                              e.currentTarget.value = `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(7, 9)}`
                              e.currentTarget.selectionEnd = s+1;
                            }
                            setOasData({
                              ...oasData,
                              entered_date: e.currentTarget.value
                            });

                          }}
                          onKeyDown={(e) => {
                            if (e.currentTarget.value.length === 10 && !isNaN(Number(e.key))) {
                              const s = e.currentTarget.selectionStart;
                              const value = e.currentTarget.value;
                              console.log(`selection: ${s} / key: ${e.key}`)

                              if (s === null) return;

                              if (s === 4 || s === 7) {
                                if (e.currentTarget.value !== `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`) {
                                  e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                }
                                else {
                                  e.preventDefault();
                                  e.currentTarget.value = `${value.slice(0, s)}-${e.key}${value.slice(s+2)}`
                                  e.currentTarget.selectionStart = s+2;
                                }
                                e.currentTarget.selectionEnd = s+2;
                              }
                              else if (s !== 10) {
                                console.log('here2')
                                e.currentTarget.value = `${value.slice(0, s)}${e.key}${value.slice(s+1)}`
                                e.currentTarget.selectionEnd = s+1;
                              }
                            }
                          }}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              entered_date: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', marginTop: 10, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>증후군 여부</div>
                    <div>
                      <form style={{border: 'none', display: 'flex', alignItems: 'center', gap: 3}}>
                        <input
                            type='radio'
                            id="allergyTrue"
                            name="allergyRadio"
                            defaultChecked={oasData.existence_of_oas}
                            disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setOasData({
                                  ...oasData,
                                  existence_of_oas: true
                                });
                              }
                              setModalState({
                                ...modalState,
                                isChanged: true
                              });
                            }} />
                        <label style={{marginTop: 3}}>Yes</label>
                        <input
                            type='radio'
                            id="allergyFalse"
                            name="allergyRadio"
                            defaultChecked={!oasData.existence_of_oas}
                            style={{marginLeft: 15}}
                            disabled={modalState.mode !== "edit" && modalState.mode !== "new"}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setOasData({
                                  entered_date: customYMD(Date.now()),
                                  existence_of_oas: false
                                });
                              }
                              setModalState({
                                ...modalState,
                                isChanged: true
                              });
                            }} />
                        <label style={{marginTop: 3}}>No</label>
                      </form>
                    </div>
                  </div>

                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginTop: 10, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>유발식품 1</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.response_allergen_1}
                          disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              response_allergen_1: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>

                    <div>유발식품 2</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.response_allergen_2}
                          disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              response_allergen_2: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>

                    <div>유발식품 3</div>
                    <div>
                      <input
                          type='text'
                          maxLength={10}
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.response_allergen_3}
                          disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              response_allergen_3: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>
                  </div>
                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginTop: 10, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>유발식품 4</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.response_allergen_4}
                          disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              response_allergen_4: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>

                    <div>유발식품 5</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.response_allergen_5}
                          disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              response_allergen_5: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>

                    <div>유발식품 6</div>
                    <div>
                      <input
                          type='text'
                          maxLength={10}
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.response_allergen_6}
                          disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              response_allergen_6: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>
                  </div>
                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginTop: 10, height: 30, fontWeight: 700, textAlign: 'center', marginRight: 30}}>
                    <div>유발식품 7</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.response_allergen_7}
                          disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              response_allergen_7: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>

                    <div>유발식품 8</div>
                    <div>
                      <input
                          type='text'
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.response_allergen_8}
                          disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              response_allergen_8: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>

                    <div>유발식품 9</div>
                    <div>
                      <input
                          type='text'
                          maxLength={10}
                          className={cn('cellTextInput')}
                          style={{textAlign: 'center', height: 25, background: '#F3F3F3', borderRadius: 8, marginTop: -5}}
                          defaultValue={oasData.response_allergen_9}
                          disabled={(modalState.mode !== "edit" && modalState.mode !== "new") || !oasData.existence_of_oas}
                          onChange={(e) => {
                            setOasData({
                              ...oasData,
                              response_allergen_9: e.target.value
                            });
                            setModalState({
                              ...modalState,
                              isChanged: true
                            })
                          }}>
                      </input>
                    </div>
                  </div>

                </div>

                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>

                  {modalState.mode === "new"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertOasData(0)
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "edit"?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                InsertOasData(modalState.metadata_id)
                              }}>
                        저장
                      </button> : null}

                  {modalState.mode === "detail" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                setModalState({
                                  ...modalState,
                                  mode: 'edit'
                                })
                              }}>
                        수정
                      </button> : null}

                  {modalState.mode === "detail" || modalState.mode === "new" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                setModalState({
                                  type: "",
                                  mode: ""
                                })
                              }}>
                        닫기
                      </button> :  null}

                  {modalState.mode === "edit" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#54595E', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                              onClick={() => {
                                if (modalState.isChanged === true) {
                                  setDialogState({
                                    isOpen: true,
                                    title: "저장되지 않은 수정 사항이 있습니다. 페이지를 닫으시겠습니까?",
                                    desc: "저장되지 않은 수정 사항은 페이지 종료 시 반영되지 않습니다.",
                                    accept_label: "닫기",
                                    deny_label: "취소",
                                    accept_callback: () => {
                                      setModalState({
                                        ...modalState,
                                        type: "",
                                        mode: ""
                                      })
                                      ReloadDetailOasData();
                                      setTimeout(() => {
                                        setModalState({
                                          ...modalState,
                                          mode: "detail",
                                          isChanged: false,
                                        })
                                        setDialogState({
                                          isOpen: false
                                        })

                                      }, 100)

                                    }
                                  })
                                }
                                else {
                                  setModalState({
                                    ...modalState,
                                    mode: "detail"
                                  })
                                }
                              }}>
                        뒤로
                      </button> :  null}
                </div>

              </div>
            </div>
            : null}

        {modalState.type === "survey" ?
            <div className={cn('modalBase')} style={{minHeight: 720}}>
              <div className={cn('modalPaper')} style={{height: 720, width: 1200, padding: 20}}>
                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>

                  {modalState.mode === "detail" ?
                      <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                        <div>설문결과</div>
                      </div>: null}

                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         setModalState({
                           type: "",
                           mode: ""
                         })
                       }}/>
                </div>

                <div style={{height: 60, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
                  <div style={{display: 'flex'}}>
                    <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
                    <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>

                  </div>
                  <div style={{fontSize: '15px', fontWeight: 400, marginTop: 10}}>설문 응답일 {surveyData.surveyed_at.slice(0,10)}</div>

                </div>

                <div style={{padding: '15px 15px', height: 45, background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', borderRadius: '8px', marginTop: 10, alignItems: 'center', fontSize: '14px', color: '#54595E'}}>
                  <div style={{display: 'grid', gridTemplateColumns: '0.7fr 0.7fr 0.7fr 0.7fr 5fr'}}>
                    <div>ADCT : {surveyData.adct_score}</div>
                    <div>{surveyData.response_2_age === 1 ? 'IDLQI' : surveyData.response_2_age === 2 ? 'CDLQI' : 'DLQI'} : {surveyData.qol_score}</div>
                    <div>DFI : {surveyData.dfi_score}</div>
                    <div>POEM : {surveyData.poem_score}</div>
                  </div>
                  <div style={{marginTop: 10}}>
                    치료부작용 : {getAdrString(surveyData) ?? "없음"}
                  </div>
                </div>


                <div style={{padding: '15px 15px', height: 500, marginTop: 10, fontSize: '16px', borderRadius: '4px', background: 'white', boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)', overflowY: 'scroll'}}>
                  <>
                    <div style={{fontSize: 16, fontWeight: 600, color: '#54595E'}}>천식 자가보고 기본 정보</div>
                    <div className={cn('surveyRow1')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div>천식 자가진단 설문이 처음이신가요?</div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_1 === '1' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_1 === 1})}>{ANSWER_DICT.response_1[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_1 === '2' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_1 === 2})}>{ANSWER_DICT.response_1[2]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div>1. 출생연도를 입력해 주세요.</div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_2 != null})}>{surveyData.response_2} 년 출생</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div>2. 성별을 선택해 주세요.</div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_3 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_3 === 1})}>{ANSWER_DICT.response_3[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_3 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_3 === 2})}>{ANSWER_DICT.response_3[2]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div>3. 아주대병원의 환자번호를 알려주세요.</div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_4 != null})}>{surveyData.response_4}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div>4. 아래 질환들 중 앓고 있으신 질환을 알려주세요. </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_5 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_5 === 1})}>{ANSWER_DICT.response_5[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_5 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_5 === 2})}>{ANSWER_DICT.response_5[2]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_5 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_5 === 3})}>{ANSWER_DICT.response_5[3]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_5 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_5 === 4})}>{ANSWER_DICT.response_5[4]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 5. 앓고 있으신 다른 질병을 기입해 주세요.</div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_6 != null})}>{surveyData.response_6}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 6. 키를 입력해 주세요.(cm)</div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_7 != null})}>{surveyData.response_7} cm</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 7. 몸무게를 알려주세요.(kg)</div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_8 != null})}>{surveyData.response_8} kg</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 8. 담배 혹은 전자담배를 피우시나요? </div>
                      <div className={cn('answerColGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_9 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_9 === 1})}>{ANSWER_DICT.response_9[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_9 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_9 === 2})}>{ANSWER_DICT.response_9[2]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_9 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_9 === 3})}>{ANSWER_DICT.response_9[3]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_9 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_9 === 4})}>{ANSWER_DICT.response_9[4]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 9. 담배 혹은 전자담배를 얼마나 오래 피우셨나요? </div>
                      <div className={cn('answerColGroup')}>
                        <div className={cn('answerRowGroup')}>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '1' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 1})}>{ANSWER_DICT.response_10[1]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '2' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 2})}>{ANSWER_DICT.response_10[2]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '3' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 3})}>{ANSWER_DICT.response_10[3]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '4' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 4})}>{ANSWER_DICT.response_10[4]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '5' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 5})}>{ANSWER_DICT.response_10[5]}</div>
                          </div>
                        </div>
                        <div className={cn('answerRowGroup')}>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '6' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 6})}>{ANSWER_DICT.response_10[6]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '7' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 7})}>{ANSWER_DICT.response_10[7]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '8' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 8})}>{ANSWER_DICT.response_10[8]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '9' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 9})}>{ANSWER_DICT.response_10[9]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_10 === '10' ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_10 === 10})}>{ANSWER_DICT.response_10[10]}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 10. 하루에 몇 값, 혹은 하루에 액상 몇 병을 피우시나요? </div>
                      <div className={cn('answerColGroup')}>
                        <div className={cn('answerRowGroup')}>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 1})}>{ANSWER_DICT.response_11[1]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 2})}>{ANSWER_DICT.response_11[2]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 3})}>{ANSWER_DICT.response_11[3]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 4})}>{ANSWER_DICT.response_11[4]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 5})}>{ANSWER_DICT.response_11[5]}</div>
                          </div>
                        </div>
                        <div className={cn('answerRowGroup')}>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 6})}>{ANSWER_DICT.response_11[6]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 7})}>{ANSWER_DICT.response_11[7]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 8})}>{ANSWER_DICT.response_11[8]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 9})}>{ANSWER_DICT.response_11[9]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 10 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_11 === 10})}>{ANSWER_DICT.response_11[10]}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={cn('surveyRow2')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 11. 담배를 언제 끊으셨나요? </div>
                      <div className={cn('answerColGroup')}>
                        <div className={cn('answerRowGroup')}>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_12 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 1})}>{ANSWER_DICT.response_12[1]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_12 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 2})}>{ANSWER_DICT.response_12[2]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_12 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 3})}>{ANSWER_DICT.response_12[3]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 4})}>{ANSWER_DICT.response_12[4]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 5})}>{ANSWER_DICT.response_12[5]}</div>
                          </div>
                        </div>
                        <div className={cn('answerRowGroup')}>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_12 === 6 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 6})}>{ANSWER_DICT.response_12[6]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_12 === 7 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 7})}>{ANSWER_DICT.response_12[7]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_12 === 8 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 8})}>{ANSWER_DICT.response_12[8]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 9 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 9})}>{ANSWER_DICT.response_12[9]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_11 === 10 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_12 === 10})}>{ANSWER_DICT.response_12[10]}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 12. 천식을 진단받은 적이 있으신가요? </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_13 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_13 === 1})}>{ANSWER_DICT.response_13[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_13 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_13 === 2})}>{ANSWER_DICT.response_13[2]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 13. 몇 년도에 천식 진단을 받으셨나요? </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_14 != null})}>{surveyData.response_14}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 14. 흡입기를 사용 중입니까? </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_15 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_15 === 1})}>{ANSWER_DICT.response_15[1]}</div>
                          </div>
                          <div className={cn('answerItem')}>
                            <img className={cn('comboIcon')} src={surveyData.response_15 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                            <div className={cn({'answerHighlightedText': surveyData.response_15 === 2})}>{ANSWER_DICT.response_15[2]}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 15. 흡입기를 사용하고 계시다면 용도에 따라 선택해 주세요. </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_16 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_16 === 1})}>{ANSWER_DICT.response_16[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_16 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_16 === 2})}>{ANSWER_DICT.response_16[2]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')} style={{marginTop: 10, marginBottom: 10}}>
                      <div/><div> 16. 규칙적으로 사용하고 있다면 하루에 사용하는 횟수를 입력하여 주세요.(회) </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_17 != null})}>{surveyData.response_17} 회</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')}>
                      <div/><div> 17. 규칙적으로 사용하고 있다면 한번 사용할 때 흡입하는 획수를 입력하여 주세요.(회) </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_18 != null})}>{surveyData.response_18} 회</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')}>
                      <div/><div> 18. 흡입기를 사용하고 있지 않거나, 흡입기 사용과 함께 어던 치료를 받고 있는지 선택해 주세요. </div>
                      <div className={cn('answerColGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_19 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_19 === 1})}>{ANSWER_DICT.response_19[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_19 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_19 === 2})}>{ANSWER_DICT.response_19[2]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_19 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_19 === 3})}>{ANSWER_DICT.response_19[3]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_19 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_19 === 4})}>{ANSWER_DICT.response_19[4]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_16 === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_19 === 5})}>{ANSWER_DICT.response_19[5]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')}>
                      <div/><div> 19. 그 외 다른 치료를 입력하여 주세요.  </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_20 != null})}>{surveyData.response_20}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')}>
                      <div/><div> 20. 지난 1년간 천식 진료를 위해 정기 외래 진료를 몇 번 받으셨나요?(횟수) </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_21 != null})}>{surveyData.response_21}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')}>
                      <div/><div> 21. 지난 1년간 천식의 증상 악화로 인한 응급실 또는 병원 진료를 몇 번 받으셨나요?(횟수) </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_22 != null})}>{surveyData.response_22}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')}>
                      <div/><div> 22. 지난 1년간 감기에 몇 번 걸리셨나요?(횟수) </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <div className={cn({'answerHighlightedText': surveyData.response_23 != null})}>{surveyData.response_23}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')}>
                      <div/><div> 23. 아토피 피부염을 겪은 적이 있나요? </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_24 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_24 === 1})}>{ANSWER_DICT.response_24[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_24 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_24 === 2})}>{ANSWER_DICT.response_24[2]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow1')}>
                      <div/><div> 24. 식품 알레르기를 겪은 적이 있나요? </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_25 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_25 === 1})}>{ANSWER_DICT.response_25[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_25 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_25 === 2})}>{ANSWER_DICT.response_25[2]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')}>
                      <div/><div> 25. 가족 중에서 아토피, 비염, 천식 환자가 있나요? </div>
                      <div className={cn('answerRowGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_26 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_26 === 1})}>{ANSWER_DICT.response_26[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_26 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_26 === 2})}>{ANSWER_DICT.response_26[2]}</div>
                        </div>
                      </div>
                    </div>
                  </>
                  <>
                    <div style={{fontSize: 16, fontWeight: 600, color: '#54595E', marginTop: 30}}>천식 증상 평가 설문 결과</div>
                    <div className={cn('surveyRow1')} style={{marginTop: 10}}>
                      <div/><div>1. 지난 4주 동안 천식 때문에 일상 활동(직, 학교, 가정)에 어려움을 겪으셨나요?</div>
                      <div className={cn('answerColumnGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_27 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_27 === 1})}>{ANSWER_DICT.response_27[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_27 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_27 === 2})}>{ANSWER_DICT.response_27[2]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_27 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_27 === 3})}>{ANSWER_DICT.response_27[3]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_27 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_27 === 4})}>{ANSWER_DICT.response_27[4]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_27 === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_27 === 5})}>{ANSWER_DICT.response_27[5]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')}>
                      <div/><div>2. 지난 4주 동안 얼마나 자주 숨쉬기가 어렵거나 헐떡였나요?</div>
                      <div className={cn('answerColumnGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_28 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_28 === 1})}>{ANSWER_DICT.response_28[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_28 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_28 === 2})}>{ANSWER_DICT.response_28[2]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_28 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_28 === 3})}>{ANSWER_DICT.response_28[3]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_28 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_28 === 4})}>{ANSWER_DICT.response_28[4]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_28 === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_28 === 5})}>{ANSWER_DICT.response_28[5]}</div>
                        </div>
                      </div>
                    </div>

                    <div className={cn('surveyRow2')}>
                      <div/><div>3. 지난 4주 동안 혹은 최근 병원 방문 후, 천식 증상(쌕쌕거리는 소리, 기침, 숨가쁨)으로 인해 잠에서 깨거나 일찍 일어나게 되었나요?</div>
                      <div className={cn('answerColumnGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_29 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_29 === 1})}>{ANSWER_DICT.response_29[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_29 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_29 === 2})}>{ANSWER_DICT.response_29[2]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_29 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_29 === 3})}>{ANSWER_DICT.response_29[3]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_29 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_29 === 4})}>{ANSWER_DICT.response_29[4]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_29 === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_29 === 5})}>{ANSWER_DICT.response_29[5]}</div>
                        </div>
                      </div>
                    </div>
                    <div className={cn('surveyRow2')}>
                      <div/><div>4. 지난 4주 동안 천식 약물 (예: 살부타몰, 페노테롤, 벤토린, 베로텍) 사용 빈도는 어떻게 되었나요?</div>
                      <div className={cn('answerColumnGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_30 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_30 === 1})}>{ANSWER_DICT.response_30[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_30 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_30 === 2})}>{ANSWER_DICT.response_30[2]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_30 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_30 === 3})}>{ANSWER_DICT.response_30[3]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_30 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_30 === 4})}>{ANSWER_DICT.response_30[4]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_30 === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_30 === 5})}>{ANSWER_DICT.response_30[5]}</div>
                        </div>
                      </div>
                    </div>
                    <div className={cn('surveyRow2')}>
                      <div/><div>5. 지난 4주 동안 천식 조절 상태를 어떻게 평가하시나요?</div>
                      <div className={cn('answerColumnGroup')}>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_31 === 1 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_31 === 1})}>{ANSWER_DICT.response_31[1]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_31 === 2 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_31 === 2})}>{ANSWER_DICT.response_31[2]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_31 === 3 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_31 === 3})}>{ANSWER_DICT.response_31[3]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_31 === 4 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_31 === 4})}>{ANSWER_DICT.response_31[4]}</div>
                        </div>
                        <div className={cn('answerItem')}>
                          <img className={cn('comboIcon')} src={surveyData.response_31 === 5 ? `./img/ic_combo_checked.png` : `./img/ic_combo_unchecked.png`} alt="user icon"/>
                          <div className={cn({'answerHighlightedText': surveyData.response_31 === 5})}>{ANSWER_DICT.response_31[5]}</div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>


                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', justifyContent: 'center', gap: 10, marginTop: 15}}>


                  {modalState.mode === "detail" ?
                      <button style={{width: 160, height: 36, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer', color: 'white', marginLeft: 20}}
                              onClick={() => {
                                setModalState({
                                  type: "",
                                  mode: ""
                                })
                              }}>
                        닫기
                      </button> :  null}

                </div>

              </div>
            </div>
            : null}

        {modalState.type === "chart" ?
            <div className={cn('modalBase')} style={{minHeight: 720}}>
              <div className={cn('modalPaper')} style={{width: 1200, height: 650, padding: 20}}>
                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>

                  <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                    <div>개인별 차트 분석</div>
                  </div>

                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         setModalState({
                           type: "",
                           mode: ""
                         })
                       }}/>
                </div>

                <div style={{display: 'flex', height: 20, borderRadius: 8, background: 'white', marginTop: 10, padding: 20, alignItems: 'center'}}>
                  <div style={{fontSize: '20px', fontWeight: 700}}>{markName(participantInfo.participant_name)}</div>
                  <div style={{fontSize: '15px', fontWeight: 400, marginTop: 5, marginLeft: 10}}> | 연구 등록 번호 {participantInfo.participant_id}</div>
                  <div style={{display: 'grid', gap: 10, padding: '8px 12px', marginLeft: 'auto', gridTemplateColumns: '70px 60px 60px 60px 60px 60px ', alignItems: 'center', fontSize: '14px',
                    background: '#ebebeb', borderRadius: '22px', boxShadow: 'inset 0 0 2px gray'}}>
                    <div className={cn({'graphButton': true, 'graphButtonSelected': chartState.tab === 'selcscore'})}
                         onClick={() => {
                           setChartState({tab: 'selfscore'});
                           LoadSelfScoreChartData(participantInfo.participant_id);
                         }}>SCORE</div>

                  </div>
                </div>


                <div style={{width: '100%', height: 500, marginTop: 10, fontSize: '12px', textAlign: 'center', borderRadius: '4px', border: '1px solid #A2A2A2', background: 'white'}}>

                  <div style={{marginLeft: '32px'}}>
                    <Line
                        datasetIdKey='id'
                        data={chartData}
                        width="1130" height="450" key="1"
                        options={{
                          plugins:{
                            legend: {
                              display: true
                            }
                          },
                          scales: {
                            x: {
                              display: true
                            },
                            y: {
                              display: true
                            }
                          },
                          responsive: false,
                          maintainAspectRatio: false
                        }}/>
                  </div>

                </div>


              </div>
            </div>
            : null}


        {isLoading ?
            <div className={cn('modalBase')} style={{minHeight: 720, backdropFilter: 'blur(4px)'}}>
              <div className={cn('modalPaper')} style={{height: 100, width: 400, padding: 15, boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)', background: '#FFFFFF', borderRadius: '8px'}}>
                <div style={{display: 'flex', height: 60, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 30, marginTop: 20}}>
                  <div className={cn('flexCenter')} style={{width: 56, height: 56, marginLeft: 30}}>
                    <CircularProgress color="neutral" />
                  </div>
                  <div>
                    <div style={{fontWeight: 600, fontSize: 20}}>데이터를 불러오는 중입니다.</div>
                    <div style={{fontWeight: 400, fontSize: 14, marginTop: 5, color: 'rgba(84, 89, 94, 0.6)'}}>잠시만 기다려주세요.</div>
                  </div>
                </div>
              </div>
            </div>
            : null}

        {dialogState.isOpen ?
            <div className={cn('modalBase')} style={{minHeight: 720, backdropFilter: 'blur(4px)'}}>
              <div className={cn('modalPaper')} style={{height: 200, width: 600, padding: 25, boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)', background: '#FFFFFF', borderRadius: '8px'}}>
                <div style={{display: 'flex', gap: 10}}>
                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         setDialogState({
                           isOpen: false
                         })
                       }}/>
                </div>
                <div style={{fontWeight: 600, fontSize: 20, marginTop: -25, width: 500}}>{dialogState.title}</div>
                <div style={{fontWeight: 400, fontSize: 14, marginTop: 10, color: 'rgba(84, 89, 94, 0.6)', whiteSpace: 'pre-wrap', lineHeight: '22px'}}>{dialogState.desc}</div>
                <div style={{display: 'flex', gap: 10, fontSize: '14px', fontWeight: 500, marginTop: 'auto', marginLeft: 'auto'}}>
                  <button style={{width: 180, height: 44, border: '1px solid #4F4F4F', background: 'none', borderRadius: '4px', cursor: 'pointer', color: '#4F4F4F'}}
                          onClick={() => {
                            setDialogState({
                              isOpen: false
                            })
                          }}>{dialogState.deny_label}</button>
                  <button style={{width: 180, height: 44, border: 'none', background: '#4F4F4F', borderRadius: '4px', cursor: 'pointer', color: 'white'}}
                          onClick={() => {
                            dialogState.accept_callback();
                          }}>{dialogState.accept_label}</button>
                </div>
              </div>
            </div>
            : null}

        {popupState.type === "message" ?
            <div className={cn('modalBase')} style={{minHeight: 720, background: 'none'}}
                 onClick={() => {
                   setPopupState({
                     type: "",
                     mode: ""
                   })
                 }}>
              <div className={cn('modalPaper')} style={{height: 100, width: 545, padding: 15, boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)', background: '#FFFFFF', borderRadius: '8px'}}>
                <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
                  <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                       onClick={() => {
                         setPopupState({
                           type: "",
                           mode: ""
                         })
                       }}/>
                </div>
                <div style={{display: 'flex', height: 60, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 30, marginTop: -15}}>
                  {popupState.mode === "success" ? <img style={{width: 56, height: 56, marginLeft: 30}} src={`./img/ic_info.png`} alt="warning icon"/> : null}
                  {popupState.mode === "fail" ? <img style={{width: 56, height: 56, marginLeft: 30}} src={`./img/ic_warning.png`} alt="warning icon"/> : null}

                  <div>
                    <div style={{fontWeight: 600, fontSize: 20}}>{popupState.title}</div>
                    <div style={{fontWeight: 400, fontSize: 14, marginTop: 5, color: 'rgba(84, 89, 94, 0.6)'}}>{popupState.desc}</div>
                  </div>
                </div>


              </div>
            </div>
            : null}

      </div>
  );
}
