import { useEffect, useState } from 'react'
import cn from 'classnames'
import './App.css'

import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import axios from 'axios'
import { userData } from './State';
import sha256 from 'crypto-js/sha256';
import { useRecoilState } from 'recoil'
import { API_HOST } from './Constants'
import { parsed_payload } from './util'
import { renderToString } from 'react-dom/server'

export default function LoginPage() {
  
  const navigate = useNavigate();

  const requestLogin = () => {
    if (userState.email === "" || userState.password === "") {
      setIsModalOpen(true);
      return;
    }

    console.log('here??')

    axios.post(`${API_HOST}/api/ecrf/login`, {
      email: userState.email,
      password: sha256(userState.password).toString()
    })
    .then((res) => {
      setUserInfoData({
        email: userState.email
      });

      localStorage.setItem('accessToken', res.data.data.access_token);
      localStorage.setItem('refreshToken', res.data.data.refresh_token);

      // if (userState.isMaintained) {
      //   localStorage.setItem('accessToken', res.data.data.access_token);
      //   localStorage.setItem('refreshToken', res.data.data.refresh_token);
      // }
      // else {
      //   sessionStorage.setItem('accessToken', res.data.data.access_token);
      //   sessionStorage.setItem('refreshToken', res.data.data.refresh_token);
      // }

      

      navigate('/');
    })
    .catch((err) => {
      setIsModalOpen(true);
    })
  }

  const [userInfoData, setUserInfoData] = useRecoilState<any>(userData);

  const [userState, setUserState] = useState({
    email: "",
    password: "",
    isMaintained: false
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {



  }, [])

  return (
    <div style={{width: '100%', minWidth: 1280, height: '100%', display: 'flex', overflow: 'hidden'}}>
      
      <div className={cn('flexCenter')} style={{width: '50%'}}>
        <div style={{width: 436, height: 474}}>
          <img style={{width: 341, height: 43}} src={`./img/login_logo.png`} alt="login page logo"/>
          <div style={{fontSize: '33px', textAlign: 'center', fontWeight: '700', marginTop: 40, height: 43}}>
            로그인
          </div>

          <div style={{fontSize: '16px', fontWeight: '400', height: 28, marginTop: 16, color: '#8A92A6'}}>ID</div>
          <input 
              id='idInput'
              type='text'
              placeholder=''
              style={{
                width: 436,
                height: 44,
                background: 'white', 
                borderRadius:'4px', 
                outline: 'none', 
                fontSize: '16px', 
                fontWeight: '600',
                lineHeight: '26px',
                letterSpacing:'-0.5px',
                boxSizing: 'border-box',
                border: '1px solid #3A57E8',
                padding: '0 16px'
              }}
              onChange={(e) => {
                setUserState(prevState => ({
                  ...prevState,
                  email: e.target.value
                }))
              }}
              >
            </input>
          <div style={{fontSize: '16px', fontWeight: '400', height: 28, marginTop: 16, color: '#8A92A6'}}>Password</div>
          <input 
              id='passwordInput'
              type='password'
              placeholder=''
              style={{
                width: 436,
                height: 44,
                background: 'white', 
                borderRadius:'4px', 
                outline: 'none', 
                fontSize: '16px', 
                fontWeight: '600',
                lineHeight: '26px',
                letterSpacing:'-0.5px',
                boxSizing: 'border-box',
                border: '1px solid #3A57E8',
                padding: '0 16px'
              }}
              onChange={(e) => {
                setUserState(prevState => ({
                  ...prevState,
                  password: e.target.value
                }))
              }}
              >
            </input>

            {/* <div style={{display: 'flex', gap: 8, height: 28, alignItems: "center", marginTop: 6}}>
              <input type="checkbox" style={{width: 16, height: 16, margin: 0, marginTop: 8}}
                onChange={(e) => {
                  setUserState(prevState => ({
                    ...prevState,
                    isMaintained: e.target.checked
                  }))

                }}/>
              <div style={{fontSize: '16px', fontWeight: '400', height: 28, marginTop: 16, color: '#8A92A6'}}>로그인 상태 유지</div>
            </div> */}

            <div className={cn('flexCenter')} style={{height: 44, marginTop: 34}}>
              <button style={{width: 188, height: 44, border: 'none', background: '#3A57E8', borderRadius: '4px', cursor: 'pointer'}}
                onClick={() => {
                  requestLogin();
                }}>
                <div style={{fontSize: '16px', fontWeight: '400', color: 'white'}}>Log in</div>
              </button>
            </div>

            <div style={{fontSize: '14px', fontWeight: '400', textAlign: 'center', marginTop: 24}}>
              계정 및 오류 문의 : mdpp_support@evidnet.co.kr
            </div>


        </div>
      </div>
      <div style={{width: '50%', height: '100%'}}>
        <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={`./img/login_bg.svg`}  alt="login page background"/>
      </div>

      {isModalOpen ?
        <div className={cn('modalBase')} style={{minHeight: 720, background: 'none'}}>
          <div className={cn('modalPaper')} style={{height: 100, width: 545, padding: 15, boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)', background: '#FFFFFF', borderRadius: '8px'}}>
            <div style={{display: 'flex', height: 35, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 10}}>
              <img style={{width: 24, height: 24, marginLeft: 'auto', alignSelf: 'start', cursor: 'pointer'}} src={`./img/btn_close.png`} alt="dashboard background"
                onClick={() => {
                  setIsModalOpen(false)
                }}/>
            </div>
            <div style={{display: 'flex', height: 60, fontSize: '22px', fontWeight: 500, alignItems: 'center', gap: 30, marginTop: -15}}>
              <img style={{width: 56, height: 56, marginLeft: 30}} src={`./img/ic_warning.png`} alt="warning icon"/>
              <div>
                <div style={{fontWeight: 600, fontSize: 20}}>로그인에 실패하였습니다!!</div>
                <div style={{fontWeight: 400, fontSize: 14, marginTop: 5, color: 'rgba(84, 89, 94, 0.6)'}}>아이디와 비밀번호를 확인해주세요.</div>
              </div>
            </div>


          </div>
        </div>
        : null}

    </div>
  );
}

